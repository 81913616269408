import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import XLSX from "xlsx";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/it";

import Spinner from "../layout/Spinner";
moment.locale("it");

class TrainingList extends Component {
  exportTraining = () => {
    const { trainings } = this.props;
    let training = [["#", "Data", "Note", "Presenti"]];
    trainings.map((t) => {
      let presenti = [];
      t.presenti.map((presente) => {
        presenti.push(presente);
        let trainingArray = [t.numero, t.data, t.note, presente];
        training.push(trainingArray);
        return null;
      });
      return null;
    });
    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(training);
    XLSX.utils.book_append_sheet(wb, wsAll, "Tutti gli allenamenti");
    XLSX.writeFile(wb, "trainings-export.xlsx");
  };
  render() {
    const { trainings, profile } = this.props;
    if (trainings) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-dumbbell'></i> Lista allenamenti
              </h2>
            </div>
            <div className='col-md-6'>
              <div className='float-right'>
                <Link to='/' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alla
                  dashboard
                </Link>
                {profile.role === "admin" ? (
                  <button
                    className='btn btn-success'
                    onClick={this.exportTraining}
                  >
                    Esporta
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12'>
              <div className='card mt-2'>
                <h5 className='card-header'>Statistiche</h5>
                <div className='card-body'>
                  <ul className='list-group'>
                    <li className='list-group-item d-flex justify-content-between align-items-center'>
                      Allenamenti totali:{" "}
                      <span className='badge badge-primary badge-pill'>
                        {trainings.length}
                      </span>
                    </li>
                    <li className='list-group-item d-flex justify-content-between align-items-center'>
                      Presenze totali:{" "}
                      <span className='badge badge-primary badge-pill'>
                        {" "}
                        {trainings.reduce(function (tot, arr) {
                          return tot + arr.presenti.length;
                        }, 0)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='table-responsive'>
            <table className='table table-striped table-bordered table-sm text-center'>
              <thead className='thead-inverse'>
                <tr>
                  <th>Numero</th>
                  <th>Data</th>
                  <th>Presenti</th>
                  <th>Note</th>
                  {profile.role === "admin" ? <th>Modifica</th> : null}
                </tr>
              </thead>
              <tbody>
                {trainings.map((training) => (
                  <tr key={training.id}>
                    <td>{training.numero}</td>
                    <td>
                      {moment(training.data.toDate()).format("DD-MM-yyyy")}
                    </td>
                    <td>{training.presenti.length}</td>
                    <td>{training.note}</td>
                    {profile.role === "admin" ? (
                      <td>
                        <Link
                          to={`/training/edit/${training.id}`}
                          className='btn btn-warning btn-sm'
                        >
                          <i className='fas fa-pen' />
                        </Link>
                      </td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

TrainingList.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "allenamenti",
      storeAs: "trainings",
      orderBy: "numero",
    },
  ]),
  connect((state, props) => ({
    trainings: state.firestore.ordered.trainings,
    profile: state.firebase.profile,
  }))
)(TrainingList);
