import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class AddUser extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    meccanografico: "",
    password: "",
    role: "",
    ot: "",
  };

  handleSubmit = (evt) => {
    const newUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      meccanografico: this.state.meccanografico,
      role: this.state.ruolo,
      ot: this.state.ot,
    };
    const { firebase, firestore, notifyUser } = this.props;

    evt.preventDefault();
    firebase
      .createUser(
        { email: newUser.email, password: this.state.password, signIn: false },
        newUser)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => (
         firestore.add({ collection: "mail" }, ({
          to: this.state.email,
          message: {
            subject: 'Creazione account piattaforma online AIA Pinerolo',
            html: `
            Carissimo/a ${this.state.firstName},<br/> 
            con la presente per confermarti l'avvenuta creazione del tuo account personale presso la piattaforma online sezionale, di cui trovi gli estremi di accesso qua di seguito. <br/>
            <hr/>
            Link alla piattaforma: https://app.aiapinerolo.it<br/>
            Email/Nome Utente: ${this.state.email}<br/> 
            Password: ${this.state.password}<br/>
            <hr/>
            La presente mail è stata generata automaticamente dal sistema, il contenuto è riservato. 
            `
          },
        })))
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      }));
    }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { message, messageType } = this.props.notify;

    return (
      <div>
        <div className='row'>
          <div className='col-md-6'>
            <Link to='/' className='btn btn-link'>
              <i className='fas fa-arrow-circle-left' /> Indietro alla dashboard
            </Link>
          </div>
        </div>
        {message ? <Alert message={message} messageType={messageType} /> : null}
        <div className='card'>
          <h5 className='card-header'>Aggiungi Utente</h5>
          <div className='card-body'>
            <form onSubmit={this.handleSubmit}>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='firstName'>Nome</label>
                    <input
                      type='text'
                      className='form-control'
                      name='firstName'
                      id='firstName'
                      minLength='2'
                      required
                      onChange={this.onChange}
                      value={this.state.firstName}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='lastName'>Cognome</label>
                    <input
                      type='text'
                      className='form-control'
                      name='lastName'
                      id='lastName'
                      minLength='2'
                      required
                      onChange={this.onChange}
                      value={this.state.lastName}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='email'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      name='email'
                      id='email'
                      onChange={this.onChange}
                      value={this.state.email}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='phone'>Telefono</label>
                    <input
                      type='phone'
                      className='form-control'
                      name='phone'
                      id='phone'
                      minLength='10'
                      onChange={this.onChange}
                      value={this.state.phone}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='meccanografico'>
                      Codice Meccanografico
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='meccanografico'
                      id='meccanografico'
                      minLength='8'
                      maxLength='8'
                      onChange={this.onChange}
                      value={this.state.meccanografico}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='password'>Password</label>
                    <input
                      type='password'
                      className='form-control'
                      name='password'
                      id='password'
                      onChange={this.onChange}
                      value={this.state.password}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='ruolo'>Ruolo</label>
                    <select
                      className='form-control'
                      name='ruolo'
                      id='ruolo'
                      onChange={this.onChange}
                      value={this.state.ruolo}
                      required
                    >
                      <option value='default'>Seleziona Associato...</option>

                      <option value='user'>Associato</option>
                      <option value='admin'>Amministratore</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='ot'>Organo Tecnico</label>
                    <select
                      className='form-control'
                      name='ot'
                      id='ot'
                      onChange={this.onChange}
                      value={this.state.ot}
                      required
                    >
                      <option value='default'>Seleziona Associato...</option>

                      <option value='OTS'>OTS</option>
                      <option value='OTR'>OTR</option>
                      <option value='CAI'>CAI</option>
                      <option value='CAN/D'>CAN/D</option>
                      <option value='CAN/C'>CAN/C</option>
                      <option value='CAN/B'> CAN/B</option>
                      <option value='CAN/A'>CAN/A</option>
                      <option value='CAN/5'>CAN/5</option>
                    </select>
                  </div>
                </div>
              </div>

              <input
                type='submit'
                className='btn btn-primary btn-block'
                value='Invia'
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(AddUser);
