import React, { Component } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class AddRto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presenti: [],
      numero: "",
      data: "",
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleCheckbox(e) {
    const item = e.target.name;
    if (e.target.checked) {
      this.setState((prevState) => prevState.presenti.push(item));
    } else {
      this.setState((prevState) => prevState.presenti.pop(item));
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const newRto = {
      numero: parseFloat(this.state.numero),
      presenti: this.state.presenti,
      data: new Date(this.state.data),
    };
    const { firestore, history, notifyUser } = this.props;
    firestore
      .add({ collection: "rto" }, newRto)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { users } = this.props;
    const { message, messageType } = this.props.notify;
    if (users) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                dashboard
              </Link>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <div className='card'>
            <h5 className='card-header'>Aggiungi RTO</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='form-group'>
                      <label htmlFor='data'>Data</label>
                      <input
                        type='datetime-local'
                        className='form-control'
                        name='data'
                        id='data'
                        required
                        onChange={this.onChange}
                        value={
                          this.state.data
                            ? this.state.data
                            : new Date(Date.now())
                                .toISOString()
                                .slice(0, -1)
                                .slice(0, 16)
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='numero'>Numero</label>
                      <input
                        type='number'
                        className='form-control'
                        name='numero'
                        id='numero'
                        required
                        onChange={this.onChange}
                        value={this.state.numero || ""}
                      />
                    </div>
                  </div>
                </div>
                <div className='table-responsive'>
                  <table className='table table-stripped '>
                    <thead className='thead-inverse'>
                      <tr>
                        <th>Nome</th>
                        <th>Cognome</th>
                        <th>P</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user.id}>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>
                            <input
                              type='checkbox'
                              name={user.id}
                              id={user.id}
                              onChange={this.handleCheckbox}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}
export default compose(
  firestoreConnect([{ collection: "utenti" }]),

  connect(
    (state, props) => ({
      users: state.firestore.ordered.utenti,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(AddRto);
