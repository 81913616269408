import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/it";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";
import Spinner from "../layout/Spinner";
moment.locale("it");

class EditRto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presenti: [],
      numero: "",
      data: "",
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleCheckbox(e) {
    const { firestore, rto } = this.props;
    if (e.target.checked) {
      firestore.update(
        { collection: "rto", doc: rto.id },
        { presenti: rto.presenti.concat([e.target.name]) }
      );
    } else {
      firestore.update(
        { collection: "rto", doc: rto.id },
        {
          presenti: rto.presenti.filter(function (val) {
            return val !== e.target.name;
          }),
        }
      );
    }
  }
  onDeleteClick = () => {
    const { rto, firestore, history, notifyUser } = this.props;
    firestore
      .delete({ collection: "rto", doc: rto.id })
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(history.push("/"));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { firestore, history, rto, notifyUser } = this.props;
    const updateRto = {
      numero: this.state.numero ? parseFloat(this.state.numero) : rto.numero,
      data: this.state.data ? new Date(this.state.data) : rto.data,
    };

    firestore
      .update({ collection: "rto", doc: rto.id }, updateRto)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { users, rto } = this.props;
    const { message, messageType } = this.props.notify;
    if (users && rto) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                dashboard
              </Link>
            </div>
            <div className='col-md-6'>
              <div className='btn-group float-right'>
                <button onClick={this.onDeleteClick} className='btn btn-danger'>
                  Elimina
                </button>
              </div>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <hr />
          <div className='card'>
            <h5 className='card-header'>Modifica RTO</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='form-group'>
                      <label htmlFor='data'>Data</label>
                      <input
                        type='datetime-local'
                        className='form-control'
                        name='data'
                        id='data'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          rto.id === this.props.match.params.id
                            ? moment(rto.data.toDate()).format(
                                "yyyy-MM-DDThh:mm"
                              )
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='numero'>Numero</label>
                      <input
                        type='number'
                        className='form-control'
                        name='numero'
                        id='numero'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          rto.id === this.props.match.params.id
                            ? rto.numero.toString()
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='table-responsive'>
                  <table className='table table-stripped '>
                    <thead className='thead-inverse'>
                      <tr>
                        <th>Nome</th>
                        <th>Cognome</th>
                        <th>P</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user.id}>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>
                            <input
                              type='checkbox'
                              key={user.id}
                              name={user.id}
                              id={user.id}
                              onChange={this.handleCheckbox}
                              defaultChecked={
                                rto.id === this.props.match.params.id
                                  ? rto.presenti.includes(user.id)
                                    ? true
                                    : false
                                  : null
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

EditRto.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "utenti",
      storeAs: "users",
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "rto",
      storeAs: "rto",
      doc: props.match.params.id,
    },
  ]),
  connect(
    (state, props) => ({
      users: state.firestore.ordered.users,
      rto: state.firestore.ordered.rto && state.firestore.ordered.rto[0],
      notify: state.notify,
    }),
    { notifyUser }
  )
)(EditRto);
