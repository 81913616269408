import React, { Component } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";
import { connect } from "react-redux";

class AddField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      descrizione: "",
      icon: "",
      nome: "",
      reqWhat: "",
      reqAmt: "",
      tipo: "",
    };
  }
  onSubmit = (e) => {
    e.preventDefault();
    const newGoal = {
      descrizione: this.state.descrizione,
      icon: this.state.icon,
      nome: this.state.nome,
      tipo: this.state.tipo,
      requirements: [this.state.reqWhat, this.state.reqAmt],
    };
    const { firestore, history, notifyUser } = this.props;
    firestore
      .add({ collection: "obbiettivi" }, newGoal)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { message, messageType } = this.props.notify;
    return (
      <div>
        <div className='row'>
          <div className='col-md-6'>
            <Link to='/' className='btn btn-link'>
              <i className='fas fa-arrow-circle-left' /> Indietro alla dashboard
            </Link>
          </div>
        </div>
        {message ? <Alert message={message} messageType={messageType} /> : null}
        <div className='card'>
          <h5 className='card-header'>Aggiungi Obbiettivo</h5>
          <div className='card-body'>
            <form onSubmit={this.onSubmit}>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='nome'>Nome</label>
                    <input
                      type='text'
                      className='form-control'
                      name='nome'
                      id='nome'
                      required
                      onChange={this.onChange}
                      value={this.state.nome || ""}
                    />
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className='form-group'>
                    <label htmlFor='descrizione'>Descrizione</label>
                    <textarea
                      className='form-control'
                      name='descrizione'
                      id='descrizione'
                      rows='4'
                      required
                      onChange={this.onChange}
                      value={this.state.descrizione || ""}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor='tipo'>Tipo Obbiettivo</label>
                    <select
                      className='form-control'
                      name='tipo'
                      id='tipo'
                      onChange={this.onChange}
                      value={this.state.tipo}
                      required
                    >
                      <option value='default'>Seleziona tipo...</option>

                      <option value='gold'>Oro</option>
                      <option value='silver'>Argento</option>
                      <option value='bronze'>Bronzo</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor='icon'>Icona</label>
                    <input
                      type='text'
                      className='form-control'
                      name='icon'
                      id='icon'
                      required
                      onChange={this.onChange}
                      value={this.state.icon || ""}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor='reqWhat'>Requisito (tipo)</label>
                    <select
                      className='form-control'
                      name='reqWhat'
                      id='reqWhat'
                      onChange={this.onChange}
                      value={this.state.reqWhat}
                      required
                    >
                      <option value='default'>Seleziona tipo...</option>

                      <option value='matches'>Partite</option>
                      <option value='trainings'>Trainings</option>
                      <option value='rto'>RTO</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor='reqAmt'>Requisito (quantità)</label>
                    <input
                      type='number'
                      className='form-control'
                      name='reqAmt'
                      id='reqAmt'
                      required
                      onChange={this.onChange}
                      value={this.state.reqAmt || ""}
                    />
                  </div>
                </div>
              </div>
              <input
                type='submit'
                className='btn btn-primary btn-block'
                value='Invia'
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect(
    (state, props) => ({
      notify: state.notify,
    }),
    { notifyUser }
  )
)(AddField);
