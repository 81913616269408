import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import classnames from "classnames";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import "moment/locale/it";
import Spinner from "../layout/Spinner";
moment.locale("it");

class Users extends Component {
  render() {
    const { users, matches, profile, auth } = this.props;
    if (users && matches) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                <i className='fas fa-users'></i> Associati
              </h2>
            </div>
          </div>
          <div className='table-responsive mt-2'>
            <table className='table table-striped table-bordered table-sm'>
              <thead className='thead-inverse'>
                {profile.role === "admin" ? (
                  <tr>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Partite</th>
                    <th>Dettagli</th>
                  </tr>
                ) : (
                  <tr>
                    <th>Meccanografico</th>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Organo Tecnico</th>
                    <th>Dettagli</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {users.map((user) =>
                  profile.role === "admin" ? (
                    <tr key={user.id}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td key={user.id + "matches"}>
                        <div className='flex-wrap' role='group'>
                          {matches.map((match) => {
                            if (match.utente === user.id) {
                              function renderTooltip(props) {
                                return (
                                  <Tooltip id='button-tooltip' {...props}>
                                    <b>Partita: </b>
                                    {match.partita.home} - {match.partita.away}
                                    <br />
                                    <b>Categoria:</b> {match.categoria}
                                    <br />
                                    <b>Data: </b>{" "}
                                    {moment(match.data.toDate()).format("L")}
                                    {match.oa === true ? (
                                      <b>
                                        <br />
                                        OA {parseFloat(match.voto).toFixed(2)}
                                      </b>
                                    ) : null}
                                    {match.ot === true ? (
                                      <b>
                                        <br />
                                        OT {parseFloat(match.voto).toFixed(2)}
                                      </b>
                                    ) : null}
                                  </Tooltip>
                                );
                              }
                              return (
                                <OverlayTrigger
                                  placement='bottom'
                                  delay={{ show: 250, hide: 150 }}
                                  overlay={renderTooltip}
                                  key={match.id}
                                >
                                  <Button
                                    key={match.id}
                                    type='button'
                                    onClick={this.onClick}
                                    className={classnames("btn-sm mt-1 mr-1", {
                                      "btn-primary":
                                        match.categoria === "Giovanissimi",
                                      "btn-sucess":
                                        match.categoria === "Allievi",
                                      "btn-warning":
                                        match.categoria === "Juniores",
                                      "btn-danger": match.categoria === "SEC",
                                      "btn-info": match.categoria === "Terza",
                                      "btn-oa": match.oa === true,
                                      "btn-ot": match.ot === true,
                                    })}
                                  >
                                    {match.oa === true ? "[OA] " : null}
                                    {match.ot === true ? "[OT] " : null}
                                    {moment(match.data.toDate()).format(
                                      "DD/MM"
                                    )}
                                  </Button>
                                </OverlayTrigger>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </td>
                      <td>
                        <Link
                          to={`/goals/${user.id}`}
                          className='btn btn-outline-primary btn-sm mt-1 mr-1'
                        >
                          <i className='fas fa-trophy'></i>
                        </Link>
                        <Link
                          to={`/user/${user.id}`}
                          className='btn btn-primary btn-sm mt-1 mr-1'
                        >
                          <i className='fas fa-arrow-circle-right'></i>
                        </Link>
                      </td>
                    </tr>
                  ) : auth.uid === user.id ? (
                    <tr key={user.id} className='table-success'>
                      <td>{user.meccanografico}</td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.ot}</td>
                      <td>
                        <Link
                          to={`/goals/${user.id}`}
                          className='btn btn-outline-primary btn-sm mr-2'
                        >
                          <i className='fas fa-trophy'></i>
                        </Link>
                        <Link
                          to={`/user/${user.id}`}
                          className='btn btn-primary btn-sm'
                        >
                          <i className='fas fa-arrow-circle-right'></i> Vedi
                          profilo
                        </Link>
                      </td>
                    </tr>
                  ) : (
                    <tr key={user.id}>
                      <td>{user.meccanografico}</td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.ot}</td>
                      <td>
                        <Link
                          to={`/goals/${user.id}`}
                          className='btn btn-outline-primary btn-sm'
                        >
                          <i className='fas fa-trophy'></i>
                        </Link>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

export default compose(
  firestoreConnect([{ collection: "utenti", orderBy: "lastName" }]),
  firestoreConnect([{ collection: "partite", orderBy: "data" }]),
  firebaseConnect(),

  connect((state, props) => ({
    users: state.firestore.ordered.utenti,
    matches: state.firestore.ordered.partite,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }))
)(Users);
