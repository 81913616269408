import React, { Component } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class AddField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      denominazione: "",
      lat: "",
      lng: "",
      squadra: "",
      tipo: "",
      creatore: "",
    };
  }
  getPosition = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        lat: parseFloat(position.coords.latitude).toFixed(5),
        lng: parseFloat(position.coords.longitude).toFixed(5),
      });
    });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const { firestore, history, auth, notifyUser } = this.props;
    const newField = {
      lat: parseFloat(this.state.lat).toFixed(5),
      lng: parseFloat(this.state.lng).toFixed(5),
      squadra: this.state.squadra,
      tipo: this.state.tipo,
      denominazione: this.state.denominazione,
      creatore: auth.uid,
    };
    firestore
      .add({ collection: "campi" }, newField)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { message, messageType } = this.props.notify;
    return (
      <div>
        <div className='row'>
          <div className='col-md-6'>
            <Link to='/' className='btn btn-link'>
              <i className='fas fa-arrow-circle-left' /> Indietro alla dashboard
            </Link>
          </div>
        </div>
        {message ? <Alert message={message} messageType={messageType} /> : null}
        <div className='card'>
          <h5 className='card-header'>Aggiungi Campo</h5>
          <div className='card-body'>
            <form onSubmit={this.onSubmit}>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='denominazione'>Denominazione</label>
                    <input
                      type='text'
                      className='form-control'
                      name='denominazione'
                      id='denominazione'
                      required
                      onChange={this.onChange}
                      value={this.state.denominazione || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='squadra'>Squadra</label>
                    <input
                      type='text'
                      className='form-control'
                      name='squadra'
                      id='squadra'
                      required
                      onChange={this.onChange}
                      value={this.state.squadra || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='tipo'>Tipo</label>
                    <select
                      className='form-control'
                      name='tipo'
                      id='tipo'
                      onChange={this.onChange}
                      value={this.state.tipo}
                      required
                    >
                      <option value='default'>Seleziona Manto...</option>
                      <option value='Erba'>Erba</option>
                      <option value='Sintetico'>Sintetico</option>
                      <option value='Ibrido'>Ibrido</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='lat'>Latitudine</label>
                    <input
                      type='number'
                      className='form-control'
                      name='lat'
                      id='lat'
                      required
                      onChange={this.onChange}
                      value={this.state.lat || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='lng'>Longitudine</label>
                    <input
                      type='number'
                      className='form-control'
                      name='lng'
                      id='lng'
                      required
                      onChange={this.onChange}
                      value={this.state.lng || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='getPosition'>
                      Inserisci la tua posizione
                    </label>
                    <input
                      type='button'
                      className='btn btn-success btn-block'
                      value='Posizione attuale'
                      id='getPosition'
                      onClick={this.getPosition}
                    />
                  </div>
                </div>
              </div>
              <input
                type='submit'
                className='btn btn-primary btn-block'
                value='Invia'
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect(
    (state, props) => ({
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(AddField);
