import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";

class FieldsList extends Component {
  constructor() {
    super();
    this.state = {
      name: "Lista Campi",
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
    };
  }
  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
  };

  render() {
    const { fields, profile } = this.props;
    if (fields && this.props.loaded) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-map-marked-alt'></i> Lista campi
              </h2>
            </div>
            <div className='col-md-6'>
              <Link to='/' className='btn btn-link float-right'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                dashboard
              </Link>
            </div>
          </div>
          <div
            className='container py-2 d-flex flex-column text-center'
            style={{ minHeight: "80vh" }}
          >
            <div className='row py-3 flex-grow-1'>
              <div className='col-12'>
                <div className='card shadow h-100'>
                  <Map
                    google={this.props.google}
                    zoom={11}
                    initialCenter={{
                      lat: 44.891589,
                      lng: 7.349378,
                    }}
                  >
                    {fields.map((field) => (
                      <Marker
                        key={field.id}
                        id={field.id}
                        title={`Campo: ${field.denominazione} - Squadra: ${field.squadra}`}
                        position={{ lat: field.lat, lng: field.lng }}
                        icon={{
                          url:
                            "https://firebasestorage.googleapis.com/v0/b/aiapine-panel.appspot.com/o/football.png?alt=media&token=bbcad600-148f-43be-8035-6ccb77f135dc",
                          anchor: new this.props.google.maps.Point(16, 16),
                          scaledSize: new this.props.google.maps.Size(32, 32),
                        }}
                        {...field}
                        onClick={this.onMarkerClick}
                      />
                    ))}

                    <InfoWindow
                      marker={this.state.activeMarker}
                      onClose={this.onInfoWindowClose}
                      visible={this.state.showingInfoWindow}
                    >
                      <div>
                        <div className='card'>
                          <h4 className='card-header'>
                            {this.state.selectedPlace.denominazione}
                          </h4>
                          <div className='card-body'>
                            <ul className='list-group'>
                              <li className='list-group-item'>
                                <h6>
                                  Squadra: {this.state.selectedPlace.squadra}
                                </h6>
                              </li>
                              <li className='list-group-item'>
                                <h6>
                                  <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={
                                      "https://maps.google.com/?q=" +
                                      this.state.selectedPlace.lat +
                                      "," +
                                      this.state.selectedPlace.lng
                                    }
                                  >
                                    Coordinate GPS:{" "}
                                    {this.state.selectedPlace.lat}{" "}
                                    {this.state.selectedPlace.lng}
                                  </a>
                                </h6>
                              </li>
                              <li className='list-group-item'>
                                <h6>Manto: {this.state.selectedPlace.tipo}</h6>
                              </li>
                              {profile.role === "admin" ? (
                                <li className='list-group-item'>
                                  <a
                                    href={`/fields/edit/${this.state.selectedPlace.id}`}
                                    className='btn btn-warning btn-sm'
                                  >
                                    <i className='fas fa-pen' /> Modifica
                                  </a>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </InfoWindow>
                  </Map>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

FieldsList.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "campi",
      storeAs: "fields",
    },
  ]),
  GoogleApiWrapper({
    apiKey: "AIzaSyBqOrHrYJ6ZITzsWtY_s-BdlNP-cHQ40U0",
  }),
  connect((state, props) => ({
    fields: state.firestore.ordered.fields,
    profile: state.firebase.profile,
  }))
)(FieldsList);
