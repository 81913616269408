import React from "react";
import Users from "../users/Users";
import Sidebar from "./Sidebar";

export default () => {
  return (
    <div className='row'>
      <div className='col-md-10'>
        <div className='alert alert-warning' role='alert'>
          <h4 className='alert-heading'>APP AIA Pinerolo - Beta!</h4>
          <p>
            Benvenuto/a nell'applicazione gestionale della nostra sezione. Ti
            ricordiamo che è attualmente in fase BETA, pertanto sei pregato di
            segnalare eventuali errori, problemi o suggerimenti (nuove
            funzionalità, ecc.) a <b>webmaster@aiapinerolo.it</b>
          </p>
        </div>
        <Users />
      </div>
      <div className='col-md-2'>
        <Sidebar />
      </div>
    </div>
  );
};
