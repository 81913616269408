import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import {
  UserIsAuthenticated,
  UserIsNotAuthenticated,
  UserIsAdmin,
} from "./helpers/auth";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";
import "firebase/analytics";
import "firebase/storage";

import { createStore, combineReducers } from "redux";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";

import notifyReducer from "./reducers/notifyReducer";

import AppNavbar from "./components/layout/AppNavbar";
import Footer from "./components/layout/Footer";
import Dashboard from "./components/layout/Dashboard";

import AddUser from "./components/users/AddUser";
import EditUser from "./components/users/EditUser";
import UserDetails from "./components/users/UserDetails";

import AddMatch from "./components/matches/AddMatch";
import EditMatch from "./components/matches/EditMatch";

import AddRto from "./components/rto/AddRto";
import EditRto from "./components/rto/EditRto";
import RtoList from "./components/rto/RtoList";

import AddTraining from "./components/trainings/AddTraining";
import EditTraining from "./components/trainings/EditTraining";
import TrainingList from "./components/trainings/TrainingList";

import FieldsList from "./components/fields/FieldsList";
import AddField from "./components/fields/AddField";
import EditField from "./components/fields/EditField";

import GoalList from "./components/goals/GoalList";
import AddGoal from "./components/goals/AddGoal";
import EditGoal from "./components/goals/EditGoal";
import LeaderList from "./components/goals/LeaderList";

import Video from "./components/video/Video";
import VideoList from "./components/video/VideoList";
import VideoList2 from "./components/video/VideoList2";
import AddVideo from "./components/video/AddVideo";
import EditVideo from "./components/video/EditVideo";

import Quiz from "./components/quiz/Quiz";
import QuizList from "./components/quiz/QuizList";
import AnswersList from "./components/quiz/AnswersList";
import AddQuiz from "./components/quiz/AddQuiz";
import EditQuiz from "./components/quiz/EditQuiz";
import AddQuestion from "./components/quiz/AddQuestion";
import EditQuestion from "./components/quiz/EditQuestion";
import QuestionsList from "./components/quiz/QuestionsList";

import Login from "./components/auth/Login";

import "./App.css";

const fbConfig = {
  apiKey: "AIzaSyA-7dr3pVSJMhw6vI5nceN0ab8DOi5GzIg",
  authDomain: "aiapine-panel.firebaseapp.com",
  databaseURL: "https://aiapine-panel.firebaseio.com",
  projectId: "aiapine-panel",
  storageBucket: "aiapine-panel.appspot.com",
  messagingSenderId: "786187835865",
  appId: "1:786187835865:web:75647d1183c77a004b61c8",
  measurementId: "G-1N9LZ2PNXZ",
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: "utenti",
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
  updateProfileOnLogin: true,
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

// Initialize other services on firebase instance
firebase.firestore();
firebase.analytics();
firebase.performance();
firebase.storage();

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  notify: notifyReducer,
});

// Create initial state
const initialState = {};

const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Router>
            <div>
              <AppNavbar />
              <div className='container'>
                <Switch>
                  <Route
                    exact
                    path='/'
                    component={UserIsAuthenticated(Dashboard)}
                  />
                  <Route
                    exact
                    path='/user/add'
                    component={
                      (UserIsAuthenticated(AddUser), UserIsAdmin(AddUser))
                    }
                  />
                  <Route
                    exact
                    path='/user/edit/:id'
                    component={
                      (UserIsAuthenticated(EditUser), UserIsAdmin(EditUser))
                    }
                  />
                  <Route
                    exact
                    path='/user/:id'
                    component={UserIsAuthenticated(UserDetails)}
                  />
                  <Route
                    exact
                    path='/match/add'
                    component={UserIsAuthenticated(AddMatch)}
                  />
                  <Route
                    exact
                    path='/match/edit/:id'
                    component={UserIsAuthenticated(EditMatch)}
                  />
                  <Route
                    exact
                    path='/rto/add'
                    component={
                      (UserIsAuthenticated(AddRto), UserIsAdmin(AddRto))
                    }
                  />
                  <Route
                    exact
                    path='/rto/edit/:id'
                    component={
                      (UserIsAuthenticated(EditRto), UserIsAdmin(EditRto))
                    }
                  />
                  <Route
                    exact
                    path='/rto/list'
                    component={UserIsAuthenticated(RtoList)}
                  />
                  <Route
                    exact
                    path='/training/add'
                    component={
                      (UserIsAuthenticated(AddTraining),
                      UserIsAdmin(AddTraining))
                    }
                  />
                  <Route
                    exact
                    path='/training/edit/:id'
                    component={
                      (UserIsAuthenticated(EditTraining),
                      UserIsAdmin(EditTraining))
                    }
                  />
                  <Route
                    exact
                    path='/training/list'
                    component={UserIsAuthenticated(TrainingList)}
                  />
                  <Route
                    exact
                    path='/fields/list'
                    component={UserIsAuthenticated(FieldsList)}
                  />
                  <Route
                    exact
                    path='/fields/add'
                    component={UserIsAuthenticated(AddField)}
                  />
                  <Route
                    exact
                    path='/fields/edit/:id'
                    component={
                      (UserIsAuthenticated(EditField), UserIsAdmin(EditField))
                    }
                  />
                  <Route
                    exact
                    path='/goals/add'
                    component={
                      (UserIsAuthenticated(AddGoal), UserIsAdmin(AddGoal))
                    }
                  />
                  <Route
                    exact
                    path='/goals/scoreboard'
                    component={UserIsAuthenticated(LeaderList)}
                  />
                  <Route
                    exact
                    path='/goals/edit/:id'
                    component={
                      (UserIsAuthenticated(EditGoal), UserIsAdmin(EditGoal))
                    }
                  />
                  <Route
                    exact
                    path='/goals/:id'
                    component={UserIsAuthenticated(GoalList)}
                  />
                  <Route
                    exact
                    path='/video/'
                    component={UserIsAuthenticated(VideoList2)}
                  />
                  <Route
                    exact
                    path='/video/add'
                    component={
                      (UserIsAuthenticated(AddVideo), UserIsAdmin(AddVideo))
                    }
                  />
                  <Route
                    exact
                    path='/video/edit/:id'
                    component={
                      (UserIsAuthenticated(EditVideo), UserIsAdmin(EditVideo))
                    }
                  />
                  <Route
                    exact
                    path='/video/:fonte/:start-:end'
                    component={UserIsAuthenticated(VideoList)}
                  />
                  <Route
                    exact
                    path='/video/:fonte/:id'
                    component={UserIsAuthenticated(Video)}
                  />
                  <Route
                    exact
                    path='/quiz/'
                    component={UserIsAuthenticated(QuizList)}
                  />
                  <Route
                    exact
                    path='/quiz/answers/:id'
                    component={(UserIsAuthenticated(AnswersList), UserIsAdmin(AnswersList))}
                  />
                  <Route
                    exact
                    path='/quiz/add'
                    component={(UserIsAuthenticated(AddQuiz), UserIsAdmin(AddQuiz))}
                  />
                  <Route
                    exact
                    path='/quiz/edit/:id'
                    component={(UserIsAuthenticated(EditQuiz), UserIsAdmin(EditQuiz))}
                  />
                  <Route
                    exact
                    path='/quiz/questions'
                    component={(UserIsAuthenticated(QuestionsList), UserIsAdmin(QuestionsList))}
                  />
                  <Route
                    exact
                    path='/quiz/:id'
                    component={UserIsAuthenticated(Quiz)}
                  />
                  <Route
                    exact
                    path='/quiz/question/add'
                    component={(UserIsAuthenticated(AddQuestion), UserIsAdmin(AddQuestion))}
                  />
                   <Route
                    exact
                    path='/quiz/question/edit/:id'
                    component={(UserIsAuthenticated(EditQuestion), UserIsAdmin(EditQuestion))}
                  />
                  
                  <Route
                    exact
                    path='/login'
                    component={UserIsNotAuthenticated(Login)}
                  />
                </Switch>
              </div>
              <Footer />
            </div>
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>
    );
  }
}

export default App;
