import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class EditVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria: "",
      codice: "",
      disciplinare: "",
      tecnico: "",
      scala: "",
      serie: "",
      spiegazione: "",
      tipo: "",
      fonte: "",
    };
  }
  onDeleteClick = () => {
    const { video, firestore, firebase, history, notifyUser } = this.props;
    firestore
      .delete({ collection: "video", doc: video.id })
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(firebase.deleteFile(`video/${video.fonte}/${video.codice}.mp4`))
      .then(history.push("/"));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { firestore, history, video, notifyUser } = this.props;
    const updateVideo = {
      categoria: this.state.categoria ? this.state.categoria : video.categoria,
      codice: this.state.codice ? this.state.codice : video.codice,
      disciplinare: this.state.disciplinare
        ? this.state.disciplinare
        : video.disciplinare,
      tecnico: this.state.tecnico ? this.state.tecnico : video.tecnico,
      scala: this.state.scala ? parseFloat(this.state.scala) : video.scala,
      serie: this.state.serie ? this.state.serie : video.serie,
      spiegazione: this.state.spiegazione
        ? this.state.spiegazione
        : video.spiegazione,
      tipo: this.state.tipo ? this.state.tipo : video.tipo,
      fonte: this.state.fonte ? this.state.fonte : video.fonte,
    };
    firestore
      .update({ collection: "video", doc: video.id }, updateVideo)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  static getDerivedStateFromProps(props, state) {
    const { campo } = props;
    if (campo && state.creatore === "") {
      return {
        creatore: campo.creatore,
      };
    }
    return null;
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { video } = this.props;
    const { message, messageType } = this.props.notify;
    if (video) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/video' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla lista
                campi
              </Link>
            </div>
            <div className='col-md-6'>
              <div className='btn-group float-right'>
                <button onClick={this.onDeleteClick} className='btn btn-danger'>
                  Elimina
                </button>
              </div>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <hr />
          <div className='card'>
            <h5 className='card-header'>Modifica Video</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='tecnico'>P. tecnico</label>
                      <select
                        className='form-control'
                        name='tecnico'
                        id='tecnico'
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.tecnico
                            : null
                        }
                        required
                      >
                        <option value='default'>
                          Seleziona provvedimento tecnico...
                        </option>
                        <option value='Niente'>Niente</option>
                        <option value='Calcio di punizione diretto'>
                          Calcio di punizione diretto
                        </option>
                        <option value='Calcio di punizione indiretto'>
                          Calcio di punizione indiretto
                        </option>
                        <option value='Calcio di rigore'>
                          Calcio di rigore
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='disciplinare'>P. disciplinare</label>
                      <select
                        className='form-control'
                        name='disciplinare'
                        id='disciplinare'
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.disciplinare
                            : null
                        }
                        required
                      >
                        <option value='default'>
                          Seleziona provvedimento disciplinare...
                        </option>
                        <option value='Niente'>Niente</option>
                        <option value='Richiamo'>Richiamo</option>
                        <option value='Ammonizione'>Ammonizione</option>
                        <option value='Espulsione'>Espulsione</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-8'>
                    <div className='form-group'>
                      <label htmlFor='spiegazione'>Spiegazione</label>
                      <textarea
                        className='form-control'
                        name='spiegazione'
                        id='spiegazione'
                        rows='4'
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.spiegazione
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='scala'>Scala</label>
                      <input
                        type='number'
                        className='form-control'
                        name='scala'
                        id='scala'
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.scala
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='codice'>Codice</label>
                      <input
                        type='text'
                        className='form-control'
                        name='codice'
                        id='codice'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.codice
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='categoria'>Categoria</label>
                      <input
                        type='text'
                        className='form-control'
                        name='categoria'
                        id='categoria'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.categoria
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='tipo'>Tipo</label>
                      <input
                        type='text'
                        className='form-control'
                        name='tipo'
                        id='tipo'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.tipo
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='serie'>Serie</label>
                      <input
                        type='text'
                        className='form-control'
                        name='serie'
                        id='serie'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.serie
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='fonte'>Fonte</label>
                      <input
                        type='text'
                        className='form-control'
                        name='fonte'
                        id='fonte'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          video.id === this.props.match.params.id
                            ? video.fonte
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

EditVideo.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "video",
      doc: props.match.params.id,
    },
  ]),
  firebaseConnect(),
  connect(
    (state, props) => ({
      users: state.firestore.ordered.users,
      video: state.firestore.ordered.video && state.firestore.ordered.video[0],
      notify: state.notify,
    }),
    { notifyUser }
  )
)(EditVideo);
