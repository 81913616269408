import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { firestoreConnect } from "react-redux-firebase";

class Leaderboard extends Component {
  goalsAmmount = (user, tipo) => {
    const { gdone } = this.props;
    const amt = [];
    gdone.map((g) =>
      user === g.utente && tipo === g.tipo ? amt.push(1) : null
    );
    return amt.length;
  };
  render() {
    const { gdone, users } = this.props;
    if (gdone && users) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-clipboard-list'></i> Scoreboard
              </h2>
            </div>
            <div className='col-md-6'>
              <div className='float-right'>
                <Link to='/' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alla
                  dashboard
                </Link>
              </div>
            </div>
          </div>

          <div className='table-responsive mt-2'>
            <table className='table table-sm table-striped table-bordered'>
              <thead className='thead-inverse'>
                <tr>
                  <th>Associato</th>
                  <th>
                    <i className='fa fa-trophy-alt text-gold'></i>
                  </th>
                  <th>
                    <i className='fa fa-trophy-alt text-silver'></i>
                  </th>
                  <th>
                    <i className='fa fa-trophy-alt text-bronze'></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      {user.firstName} {user.lastName}
                    </td>
                    <td>{this.goalsAmmount(user.id, "gold")}</td>
                    <td>{this.goalsAmmount(user.id, "silver")}</td>
                    <td>{this.goalsAmmount(user.id, "bronze")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "utenti",
      storeAs: "users",
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "obbiettivi_raggiunti",
      storeAs: "gdone",
    },
  ]),
  connect((state, props) => ({
    gdone: state.firestore.ordered.gdone,
    users: state.firestore.ordered.users,
  }))
)(Leaderboard);
