import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";
import Spinner from "../layout/Spinner";

class QuestionsList extends Component {
  deleteQuestion = (id) => {
    const {firestore} = this.props;
    firestore.delete({collection: 'domande', doc: id})
    .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      }).then(() => {
        notifyUser(
          `La domanda ${id} è stata cancellata con successo.`,
          "success"
        );
        setTimeout(() => notifyUser(null, null), 4000);
      })
  }
  render() {
    const { domande } = this.props;
    const { message, messageType } = this.props.notify;
    if (domande) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-running'></i> Lista Domande
              </h2>
            </div>
            <div className='col-md-6'>
              <div className='float-right'>
                <Link to='/' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alla
                  dashboard
                </Link>
              </div>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <hr />
          <div className='table-responsive'>
            <table className='table table-striped table-bordered table-sm text-center'>
              <thead className='thead-inverse'>
                <tr>
                  <th>Numero</th>
                  <th>Regola</th>
                  <th>Tipo</th>
                  <th>Modifica</th>
                </tr>
              </thead>
              <tbody>
                {domande.map((domanda) => (
                  <tr key={domanda.id}>
                    <td>{domanda.numero}</td>
                    <td>{domanda.regola}</td>
                    <td>{domanda.tipo}</td>
                    <td>
                      <Link
                        to={`/quiz/question/edit/${domanda.id}`}
                        className="btn btn-sm btn-warning"
                      >
                        <i className='fa fa-pen'></i>
                      </Link>
                      <button
                          onClick={() => this.deleteQuestion(domanda.id)}
                          className='btn btn-sm btn-danger ml-1'
                        >
                          <i className='fas fa-trash' />
                    </button> 
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}
QuestionsList.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "domande",
      orderBy: 'numero',
    },
  ]),
  connect((state, props) => ({
    domande: state.firestore.ordered.domande,
    profile: state.firebase.profile,
    notify: state.notify,
  }),
  { notifyUser })
)(QuestionsList);
