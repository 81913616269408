import React, { Component } from "react";
import { firebaseConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onSubmit = (e) => {
    e.preventDefault();
    const { firebase, notifyUser } = this.props;
    const { email, password } = this.state;
    firebase
      .login({ email, password })
      .catch((err) => notifyUser("Invalid Login Credentials", "error"))
      .then(setTimeout(() => notifyUser(null, null), 3000));
  };
  render() {
    const { message, messageType } = this.props.notify;
    return (
      <div className='row'>
        <div className='col-md-6 mx-auto'>
          <div className='card'>
            <div className='card-body'>
              {message !== null ? (
                <Alert message={message} messageType={messageType} />
              ) : null}
              <h1 className='text-center pb-4 pt-3'>
                <span className='text-primary'>
                  <i className='fas fa-lock'></i> Login
                </span>
              </h1>
              <form onSubmit={this.onSubmit}>
                <div className='form-group'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    name='email'
                    required
                    value={this.state.email}
                    className='form-control'
                    onChange={this.onChange}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='password'>Password</label>
                  <input
                    type='password'
                    name='password'
                    required
                    value={this.state.password}
                    className='form-control'
                    onChange={this.onChange}
                  />
                </div>
                <input
                  type='submit'
                  value='Login'
                  className='btn btn-primary btn-block'
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  firebase: PropTypes.object.isRequired,
};

export default compose(
  firebaseConnect(),
  connect(
    (state) => ({
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Login);
