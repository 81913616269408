import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import { ProgressBar } from "react-bootstrap";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";
import Spinner from "../layout/Spinner";

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      started: false,
      domanda: 0,
      risposte: [],
      domande: [],
      finished: false,
      risultato: {},
      time: Date.now(),
      disabled: false,
    };
  }

  startQuiz = () => {
    const temp = [];
    const { quiz, domande } = this.props;
    if (quiz) {
      quiz.domande.map((domanda) =>
        domande.map((d) => {
          if (d.numero === domanda) {
            temp.push(d);
          }
          return null;
        })
      );
      this.setState({ domande: temp });
      this.setState({ started: true, disabled: false });
    }
  };
  onQuestionClick = (e, numero, corretta, risposta) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ disabled: true }, () => {
      const temp = [...this.state.risposte];
      var tempObj = {};
      tempObj = {numero: parseFloat(numero), corretta, risposta: risposta ? risposta : null}
      temp.push(tempObj);
      this.setState({ risposte: temp }, () => {
        if (this.state.domanda !== this.state.domande.length - 1) {
          const nextQuestion = this.state.domanda + 1;
          this.setState({
            domanda: nextQuestion,
            time: Date.now(),
            disabled: false,
          });
        } else {
          const { firestore, notifyUser, auth } = this.props;
            this.setState({ finished: true }, () => {
              const risposte = this.state.risposte;
              const dCorrette = [];
                risposte.map((r) =>
                  r.corretta === true ? dCorrette.push(true) : null
                )
              this.setState({
                risultato: {
                  corrette: dCorrette.length,
                  sbagliate: risposte.length - dCorrette.length,
                  totali: risposte.length,
                  disabled: false,
                },
              }, () => {
                firestore.add(
                  { collection: "risposte" },
                  {
                    domande: this.state.risposte,
                    quiz: this.props.quiz.codice,
                    utente: auth.uid,
                    corrette: this.state.risultato.corrette,
                    sbagliate: this.state.risultato.sbagliate,
                    data: new Date(Date.now()).toISOString().slice(0, -1).slice(0, 16)
                  }
                )
                .catch((e) => {
                  notifyUser(e.message, "error");
                  setTimeout(() => notifyUser(null, null), 4000);
                })})
              });
        }
      });
    });
  };

  render() {
    const { domande, domanda } = this.state;
    const { message, messageType } = this.props.notify;
    const { quiz, auth } = this.props;
    const renderer = ({ seconds }) => {
      return (
        <ProgressBar
          style={{ height: "25px", fontSize: "18px" }}
          animated
          now={(seconds / 15) * 100}
          label={seconds}
        />
      );
    };
    if (quiz) {
      return (
        <div>
          <h1>Sistema Quiz Certificati</h1>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          {!this.state.finished ? ( 
            !this.state.started ? (
              <div className='col-md-12 alert alert-danger mt-4'>
                <h4 className='alert-heading'>Leggi prima di iniziare!</h4>
                <p>
                  Clicca sul pulsante qui sotto per iniziare il quiz. Una volta
                  iniziato non potrai più tornare indietro ed il tempo non si
                  fermerà fino alla fine del test. <br />
                  <b>ATTENZIONE!</b> Durante l'esecuzione del test non devi assolutamente chiudere la pagina, aggiornare la pagina od effettuare altre operazioni. Pena l'annullamento della prova.<br/>
                  <b>
                    Codice: {quiz.codice} | ID: {quiz.id}
                  </b>
                </p>
                <hr />
                <button
                  onClick={this.startQuiz}
                  className='btn btn-lg btn-primary btn-block'
                >
                  Inizia il quiz
                </button>
              </div>
            ) : this.state.domanda !== this.state.domande.length ? (
              <div>
                <h2>
                  <span className="badge badge-primary">{(this.state.domanda + 1) +'/'+quiz.domande.length}</span> Domanda {domande[domanda].numero} | Regola{" "}
                  {domande[domanda].regola}
                </h2>
                <Countdown
                  date={Date.now() + 15000}
                  key={this.state.time.toString()}
                  renderer={renderer}
                  onComplete={() =>
                    this.onQuestionClick(null, domande[domanda].numero, false)
                  }
                  className='mt-3 mb-3'
                />
                <hr />
                <h4 className='mt-3 mb-3 text-justify'>{domande[domanda].testo}</h4>
                <div className='row mt-3 mb-3 justify-content-center'>
                  {domande[domanda].risposte.map((r) => (
                    <div className='col-md-3' key={r.risposta}>
                      <Link
                        to='#'
                        value={r.risposta}
                        className={this.state.disabled ? "btn disabled" : null}
                        onClick={(e) =>
                          this.onQuestionClick(
                            e,
                            domande[domanda].numero,
                            r.corretta,
                            r.risposta
                          )
                        }
                      >
                        {r.risposta !== null && r.testo !== null && r.corretta !== null ? (
                        <div className='card h-100 d-flex flex-column'>
                          {domande[domanda].tipo === "multi" ? (
                            
                            <div className='card-body'>
                              <h4 className='card-title text-center'>
                                Risposta {r.risposta}
                              </h4>
                              <p className='card-text'>{r.testo}</p>
                            </div>
                          ) : (
                            <div className='card-body'>
                              <h4 className='card-title'>{r.risposta}</h4>
                            </div>
                          )}
                        </div>
                        ): null}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ) : null
          ) : (
            <div className='col-md-12 alert alert-success mt-4'>
            <h4 className='alert-heading'>Congratulazioni! Hai terminato</h4>
            <p>
              I dati sono stati correttamente validati e, nelle loro integrità, sono stati salvati nel cloud dedicato. <br/>
              Il risultato è certificato dai seguenti ID: Q: {quiz.id} | A: {auth.uid}<br />
              <b>
                Risposte corrette:{" "}
                {this.state.risultato.corrette +
                  " / " +
                  this.state.risultato.totali}
              </b>
            </p>
            <hr />
            <Link
              to='/quiz'
              className='btn btn-lg btn-primary btn-block'
            >
              Torna alla lista quiz
            </Link>
          </div>
          )}
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "quiz",
      doc: props.match.params.id
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "domande",
    },
  ]),
  firebaseConnect(),
  connect(
    (state, props) => ({
      quiz: state.firestore.ordered.quiz && state.firestore.ordered.quiz[0],
      domande: state.firestore.ordered.domande,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Quiz);
