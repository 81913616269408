import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class EditField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descrizione: "",
      icon: "",
      nome: "",
      reqWhat: "",
      reqAmt: "",
      tipo: "",
    };
  }
  onDeleteClick = () => {
    const { obbiettivo, firestore, history, notifyUser } = this.props;
    firestore
      .delete({ collection: "obbiettivi", doc: obbiettivo.id })
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(history.push("/"));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { firestore, history, obbiettivo, notifyUser } = this.props;
    const updateObbiettivo = {
      descrizione: this.state.descrizione
        ? this.state.descrizione
        : obbiettivo.descrizione,
      icon: this.state.icon ? this.state.icon : obbiettivo.icon,
      nome: this.state.nome ? this.state.nome : obbiettivo.nome,
      reqWhat: this.state.reqWhat ? this.state.reqWhat : obbiettivo.reqWhat,
      reqAmt: this.state.reqAmt
        ? parseFloat(this.state.reqAmt)
        : obbiettivo.reqAmt,
      tipo: this.state.tipo ? this.state.tipo : obbiettivo.tipo,
    };
    firestore
      .update(
        { collection: "obbiettivi", doc: obbiettivo.id },
        updateObbiettivo
      )
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { obbiettivo } = this.props;
    const { message, messageType } = this.props.notify;
    if (obbiettivo) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                dashboard
              </Link>
            </div>
            <div className='col-md-6'>
              <div className='btn-group float-right'>
                <button onClick={this.onDeleteClick} className='btn btn-danger'>
                  Elimina
                </button>
              </div>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <hr />
          <div className='card'>
            <h5 className='card-header'>Modifica Obbiettivo</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='nome'>Nome</label>
                      <input
                        type='text'
                        className='form-control'
                        name='nome'
                        id='nome'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          obbiettivo.id === this.props.match.params.id
                            ? obbiettivo.nome
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-8'>
                    <div className='form-group'>
                      <label htmlFor='descrizione'>Descrizione</label>
                      <textarea
                        className='form-control'
                        name='descrizione'
                        id='descrizione'
                        rows='4'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          obbiettivo.id === this.props.match.params.id
                            ? obbiettivo.descrizione
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='tipo'>Tipo Obbiettivo</label>
                      <select
                        className='form-control'
                        name='tipo'
                        id='tipo'
                        onChange={this.onChange}
                        defaultValue={
                          obbiettivo.id === this.props.match.params.id
                            ? obbiettivo.tipo
                            : null
                        }
                        required
                      >
                        <option value='default'>Seleziona tipo...</option>

                        <option value='gold'>Oro</option>
                        <option value='silver'>Argento</option>
                        <option value='bronze'>Bronzo</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='icon'>Icona</label>
                      <input
                        type='text'
                        className='form-control'
                        name='icon'
                        id='icon'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          obbiettivo.id === this.props.match.params.id
                            ? obbiettivo.icon
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='reqWhat'>Requisito (tipo)</label>
                      <select
                        className='form-control'
                        name='reqWhat'
                        id='reqWhat'
                        onChange={this.onChange}
                        defaultValue={
                          obbiettivo.id === this.props.match.params.id
                            ? obbiettivo.reqWhat
                            : null
                        }
                        required
                      >
                        <option value='default'>Seleziona tipo...</option>

                        <option value='matches'>Partite</option>
                        <option value='trainings'>Trainings</option>
                        <option value='rto'>RTO</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='reqAmt'>Requisito (quantità)</label>
                      <input
                        type='number'
                        className='form-control'
                        name='reqAmt'
                        id='reqAmt'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          obbiettivo.id === this.props.match.params.id
                            ? obbiettivo.reqAmt
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

EditField.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "obbiettivi",
      storeAs: "obbiettivo",
      doc: props.match.params.id,
    },
  ]),
  connect(
    (state, props) => ({
      obbiettivo:
        state.firestore.ordered.obbiettivo &&
        state.firestore.ordered.obbiettivo[0],
      notify: state.notify,
    }),
    { notifyUser }
  )
)(EditField);
