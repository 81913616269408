import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import classnames from "classnames";
import { firestoreConnect } from "react-redux-firebase";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class GoalList extends Component {
  checkGoal = (id, tipo, ammount, trofeo) => {
    const { matches, rto, trainings, firestore, notifyUser } = this.props;
    const newGoal = {
      utente: this.props.match.params.id,
      completato: new Date(Date.now()),
      goal: id,
      tipo: trofeo,
    };
    if (tipo === "matches" && matches.length >= ammount) {
      firestore
        .add({ collection: "obbiettivi_raggiunti" }, newGoal)
        .then(() => {
          notifyUser(
            "Congratulazioni! Hai completato correttamente l'obbiettivo. ID: " +
              id,
            "success"
          );
          setTimeout(() => notifyUser(null, null), 4000);
        })
        .catch((e) => {
          notifyUser("Errore. Riprovare o contattare il webmaster.", "error");
          setTimeout(() => notifyUser(null, null), 4000);
        });
    } else if (tipo === "rto" && rto.length >= ammount) {
      firestore
        .add({ collection: "obbiettivi_raggiunti" }, newGoal)
        .then(() => {
          notifyUser(
            "Congratulazioni! Hai completato correttamente l'obbiettivo. ID: " +
              id,
            "success"
          );
          setTimeout(() => notifyUser(null, null), 4000);
        })
        .catch((e) => {
          notifyUser("Errore. Riprovare o contattare il webmaster.", "error");
          setTimeout(() => notifyUser(null, null), 4000);
        });
    } else if (tipo === "trainings" && trainings.length >= ammount) {
      firestore
        .add({ collection: "obbiettivi_raggiunti" }, newGoal)
        .then(() => {
          notifyUser(
            "Congratulazioni! Hai completato correttamente l'obbiettivo. ID: " +
              id,
            "success"
          );
          setTimeout(() => notifyUser(null, null), 4000);
        })
        .catch((e) => {
          notifyUser("Errore. Riprovare o contattare il webmaster.", "error");
          setTimeout(() => notifyUser(null, null), 4000);
        });
    } else {
      notifyUser(
        "Non puoi ancora completare questo obbiettivo! Impegnati e raggiungilo quanto prima.",
        "error"
      );
      setTimeout(() => notifyUser(null, null), 4000);
    }
  };
  listGoal = (goal, gdone, user) => {
    let result = gdone.filter(function (e) {
      return e.goal === goal.id;
    });
    if (result[0]) {
      return (
        <h5 key={result[0].id}>
          Status:{" "}
          <span className='badge badge-success'>Obbiettivo completato</span>
        </h5>
      );
    } else {
      const { auth, profile } = this.props;
      if (auth.uid === this.props.match.params.id || profile.role === "admin") {
        return (
          <button
            className='btn btn-primary btn-block text-truncate mt-auto'
            onClick={() => {
              this.checkGoal(
                goal.id,
                goal.requirements[0],
                goal.requirements[1],
                goal.tipo
              );
            }}
          >
            Verifica
          </button>
        );
      }
    }
  };
  goalsAmmount = (tipo, gdone) => {
    const amt = [];
    gdone.map((g) => (g.tipo === tipo ? amt.push(1) : null));
    return amt.length;
  };
  render() {
    const { matches, rto, trainings, user, goals, gdone, profile } = this.props;
    const { message, messageType } = this.props.notify;
    if (matches && rto && trainings && user && goals && gdone) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h1>Obbiettivi di {user.firstName}</h1>
            </div>
            <div className='col-md-6'>
              <Link to='/' className='btn btn-link float-right'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                dashboard
              </Link>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <div className='row mt-4 text-center'>
            <div className='col-md-4'>
              <h2>
                <i className='fa fa-trophy-alt text-gold'></i>{" "}
                <span className='badge badge-secondary'>
                  {this.goalsAmmount("gold", gdone)}
                </span>
              </h2>
            </div>
            <div className='col-md-4'>
              <h2>
                <i className='fa fa-trophy-alt text-silver'></i>{" "}
                <span className='badge badge-secondary'>
                  {this.goalsAmmount("silver", gdone)}
                </span>
              </h2>
            </div>
            <div className='col-md-4'>
              <h2>
                <i className='fa fa-trophy-alt text-bronze'></i>{" "}
                <span className='badge badge-secondary'>
                  {this.goalsAmmount("bronze", gdone)}
                </span>
              </h2>
            </div>
          </div>
          <div className='row mt-4'>
            {goals.map((goal) => (
              <div className='col-md-4 py-3' key={goal.id}>
                <div
                  className={classnames("card h-100 border", {
                    "border-bronze": goal.tipo === "bronze",
                    "border-silver": goal.tipo === "silver",
                    "border-gold": goal.tipo === "gold",
                  })}
                >
                  <h1
                    className={classnames("text-center", {
                      "text-bronze": goal.tipo === "bronze",
                      "text-silver": goal.tipo === "silver",
                      "text-gold": goal.tipo === "gold",
                    })}
                    style={{ fontSize: "5rem" }}
                  >
                    <i className={"fa " + goal.icon}></i>
                  </h1>
                  <div className='card-body h-100 d-flex flex-column'>
                    <h5 className='card-title'>
                      {goal.nome}{" "}
                      {profile.role === "admin" ? (
                        <span>
                          <Link
                            to={`/goals/edit/${goal.id}`}
                            className='btn btn-warning btn-sm'
                          >
                            <i className='fas fa-pen'></i>
                          </Link>
                        </span>
                      ) : null}
                    </h5>
                    <p className='card-text'>{goal.descrizione}</p>
                    {this.listGoal(goal, gdone, user)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "utenti",
      storeAs: "user",
      doc: props.match.params.id,
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "obbiettivi",
      storeAs: "goals",
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "partite",
      storeAs: "matches",
      where: ["utente", "==", props.match.params.id],
      orderBy: "data",
    },
  ]),

  firestoreConnect((props) => [
    {
      collection: "obbiettivi_raggiunti",
      storeAs: "gdone",
      where: ["utente", "==", props.match.params.id],
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "rto",
      storeAs: "rto",
      where: ["presenti", "array-contains", props.match.params.id],
      orderBy: "numero",
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "allenamenti",
      storeAs: "trainings",
      where: ["presenti", "array-contains", props.match.params.id],
      orderBy: "numero",
    },
  ]),
  connect(
    (state, props) => ({
      matches: state.firestore.ordered.matches,
      goals: state.firestore.ordered.goals,
      rto: state.firestore.ordered.rto,
      trainings: state.firestore.ordered.trainings,
      gdone: state.firestore.ordered.gdone,
      user: state.firestore.ordered.user && state.firestore.ordered.user[0],
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(GoalList);
