import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.firstNameInput = React.createRef();
    this.lastNameInput = React.createRef();
    this.emailInput = React.createRef();
    this.phoneInput = React.createRef();
    this.meccanograficoInput = React.createRef();
    this.ruoloInput = React.createRef();
    this.otInput = React.createRef();
  }
  onSubmit = (e) => {
    e.preventDefault();
    const { user, firestore, history } = this.props;
    const updateUser = {
      firstName: this.firstNameInput.current.value,
      lastName: this.lastNameInput.current.value,
      email: this.emailInput.current.value,
      phone: this.phoneInput.current.value,
      meccanografico: this.meccanograficoInput.current.value,
      role: this.ruoloInput.current.value,
      ot: this.otInput.current.value,
    };
    firestore
      .update({ collection: "utenti", doc: user.id }, updateUser)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(history.push("/"));
  };
  render() {
    const { user } = this.props;
    const { message, messageType } = this.props.notify;
    if (user) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                dashboard
              </Link>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <div className='card'>
            <h5 className='card-header'>Modifica Associato</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='firstName'>Nome</label>
                      <input
                        type='text'
                        className='form-control'
                        name='firstName'
                        id='firstName'
                        minLength='2'
                        required
                        ref={this.firstNameInput}
                        defaultValue={user.firstName}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='lastName'>Cognome</label>
                      <input
                        type='text'
                        className='form-control'
                        name='lastName'
                        id='lastName'
                        minLength='2'
                        required
                        ref={this.lastNameInput}
                        defaultValue={user.lastName}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='email'>Email</label>
                      <input
                        type='email'
                        className='form-control'
                        name='email'
                        id='email'
                        ref={this.emailInput}
                        defaultValue={user.email}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='phone'>Telefono</label>
                      <input
                        type='phone'
                        className='form-control'
                        name='phone'
                        id='phone'
                        minLength='10'
                        ref={this.phoneInput}
                        defaultValue={user.phone}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='meccanografico'>
                        Codice Meccanografico
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='meccanografico'
                        id='meccanografico'
                        ref={this.meccanograficoInput}
                        defaultValue={user.meccanografico}
                        minLength='8'
                        maxLength='8'
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='ruolo'>Ruolo</label>
                      <select
                        className='form-control'
                        name='ruolo'
                        id='ruolo'
                        ref={this.ruoloInput}
                        defaultValue={user.role}
                        required
                      >
                        <option value='default'>Seleziona Associato...</option>

                        <option value='user'>Associato</option>
                        <option value='admin'>Amministratore</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='ot'>Organo Tecnico</label>
                      <select
                        className='form-control'
                        name='ot'
                        id='ot'
                        ref={this.otInput}
                        defaultValue={user.ot}
                        required
                      >
                        <option value='default'>Seleziona Associato...</option>

                        <option value='OTS'>OTS</option>
                        <option value='OTR'>OTR</option>
                        <option value='CAI'>CAI</option>
                        <option value='CAN/D'>CAN/D</option>
                        <option value='CAN/C'>CAN/C</option>
                        <option value='CAN/B'> CAN/B</option>
                        <option value='CAN/A'>CAN/A</option>
                        <option value='CAN/5'>CAN/5</option>
                      </select>
                    </div>
                  </div>
                </div>

                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

EditUser.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "utenti",
      storeAs: "user",
      doc: props.match.params.id,
    },
  ]),
  connect(
    (state, props) => ({
      user: state.firestore.ordered.user && state.firestore.ordered.user[0],
      notify: state.notify,
    }),
    { notifyUser }
  )
)(EditUser);
