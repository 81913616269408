import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class EditField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      denominazione: "",
      lat: "",
      lng: "",
      squadra: "",
      tipo: "",
      creatore: "",
    };
  }
  onDeleteClick = () => {
    const { campo, firestore, history, notifyUser } = this.props;
    firestore
      .delete({ collection: "campi", doc: campo.id })
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(history.push("/"));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { firestore, history, campo, notifyUser } = this.props;
    const updateCampo = {
      denominazione: this.state.denominazione
        ? this.state.denominazione
        : campo.denominazione,
      lat: this.state.lat ? parseFloat(this.state.lat).toFixed(5) : campo.lat,
      lng: this.state.lng ? parseFloat(this.state.lng).toFixed(5) : campo.lng,
      squadra: this.state.squadra ? this.state.squadra : campo.squadra,
      tipo: this.state.tipo ? this.state.tipo : campo.tipo,
      creatore: this.state.creatore ? this.state.creatore : campo.creatore,
    };
    firestore
      .update({ collection: "campi", doc: campo.id }, updateCampo)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  static getDerivedStateFromProps(props, state) {
    const { campo } = props;
    if (campo && state.creatore === "") {
      return {
        creatore: campo.creatore,
      };
    }
    return null;
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { users, campo } = this.props;
    const { message, messageType } = this.props.notify;
    if (users && campo) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/fields/list' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla lista
                campi
              </Link>
            </div>
            <div className='col-md-6'>
              <div className='btn-group float-right'>
                <button onClick={this.onDeleteClick} className='btn btn-danger'>
                  Elimina
                </button>
              </div>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <hr />
          <div className='card'>
            <h5 className='card-header'>Modifica Campo</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='denominazione'>Denominazione</label>
                      <input
                        type='text'
                        className='form-control'
                        name='denominazione'
                        id='denominazione'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          campo.id === this.props.match.params.id
                            ? campo.denominazione
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='squadra'>Squadra</label>
                      <input
                        type='text'
                        className='form-control'
                        name='squadra'
                        id='squadra'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          campo.id === this.props.match.params.id
                            ? campo.squadra
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='tipo'>Tipo</label>
                      <select
                        className='form-control'
                        name='tipo'
                        id='tipo'
                        onChange={this.onChange}
                        defaultValue={
                          campo.id === this.props.match.params.id
                            ? campo.tipo
                            : null
                        }
                        required
                      >
                        <option value='default'>Seleziona Manto...</option>
                        <option value='Erba'>Erba</option>
                        <option value='Sintetico'>Sintetico</option>
                        <option value='Ibrido'>Ibrido</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='lat'>Latitudine</label>
                      <input
                        type='text'
                        className='form-control'
                        name='lat'
                        id='lat'
                        required
                        onChange={this.onChange}
                        defaultValue={
                          campo.id === this.props.match.params.id
                            ? parseFloat(campo.lat).toFixed(5)
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='lng'>Longitudine</label>
                      <input
                        type='text'
                        className='form-control'
                        name='lng'
                        id='lng'
                        onChange={this.onChange}
                        defaultValue={
                          campo.id === this.props.match.params.id
                            ? parseFloat(campo.lng).toFixed(5)
                            : null
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='creatore'>Creatore</label>
                      <select
                        className='form-control'
                        name='creatore'
                        id='creatore'
                        onChange={this.onChange}
                        value={this.state.creatore}
                        required
                      >
                        <option value='default'>Seleziona Utente...</option>
                        {users.map((user) => (
                          <option value={user.id} key={user.id}>
                            {user.firstName + " " + user.lastName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

EditField.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "utenti",
      storeAs: "users",
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "campi",
      storeAs: "campo",
      doc: props.match.params.id,
    },
  ]),
  connect(
    (state, props) => ({
      users: state.firestore.ordered.users,
      campo: state.firestore.ordered.campo && state.firestore.ordered.campo[0],
      notify: state.notify,
    }),
    { notifyUser }
  )
)(EditField);
