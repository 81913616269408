import React, { Component, useMemo } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { PDFDownloadLink, Document, Page, Text, Font, Image, View, StyleSheet } from '@react-pdf/renderer'
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

Font.register({ family: 'Saira',  fonts: [{src: '/webfonts/SairaCondensed-Regular.ttf'}, {src: '/webfonts/SairaCondensed-Bold.ttf', fontStyle: 'normal', fontWeight: 'bold',}] });
const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 35,
    paddingHorizontal: 35,
    fontFamily: 'Saira',
  },
  element :{
    fontSize: 18,
    lineHeight: '50%',
  },
  footer: { 
    marginTop: 10,
    marginBottom: 30,
    textAlign: 'justify',
  },
  title: {
    fontSize: 30,
    fontWeight: 700,
    margin: 5,
    color: '#383838',
    textAlign: 'center',
  },
  title2: {
    fontSize: 24,
    color: '#383838',
    textAlign: 'center',
    fontWeight: 700
  },
  image: {
    marginVertical: 10,
    marginHorizontal: 150,
    width: '40%'
  },
  header: {
    fontSize: 12,
    marginBottom: 4,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

class AnswerList extends Component {
  render() {
    const { risposte, users, quiz } = this.props;
    const MyDoc = () => (
      <Document>
        
            {risposte.map((risposta) => {
              return (
                <Page style={styles.body} size="A4" wrap>
          <Text style={styles.header} fixed>
            Sistema Informativo Sezionale per la somministrazione di quiz certificati
          </Text>
          <Image
            style={styles.image}
            src="https://i1.wp.com/www.aiapinerolo.it/wp-content/uploads/2019/03/BannerIstituzionale.png?w=530&ssl=1"
          />
          <Text style={styles.title}>REPORT QUIZ {this.props.match.params.id}</Text>
          <View>
            <Text style={styles.title2}>
              Dati del Quiz
            </Text>
            <Text style={styles.element}>
              Codice Quiz: {quiz.codice}
            </Text>
            <Text style={styles.element}>
              Certificato Quiz: {quiz.id}
            </Text>
            <Text style={styles.element}>
              Codice Certificato: {risposta.id}
            </Text>
            <Text style={styles.element}>
              Candidato: {users.map((user) => (user.id === risposta.utente ? user.firstName+' '+user.lastName : null))}
            </Text>
            
          </View>
          <View>
            <Text style={styles.title2}>
            Risposte
            </Text>
                <View key={risposta.id}>
                  <Text style={styles.element}>
                    Corrette: {risposta.corrette} | Errate: {risposta.sbagliate} | Percentuale: {parseFloat(risposta.corrette/(risposta.corrette+risposta.sbagliate)*100).toFixed(2)}%
                  </Text>
                  <View style={{flexDirection:'row', display:'flex', flexWrap:'wrap'}}>
                  {risposta.domande.map((d) => 
                  <View>
                  <Text style={{color: d.corretta === true ? 'green' : 'red', padding: '8px'}}>{d.numero} - {d.risposta === null ? 'N/R' : d.risposta}
                  </Text>
                  </View>
                  )}
                  </View>
                  <View
                    style={{
                      borderBottomColor: '#2d6fb2',
                      borderBottomWidth: 3,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  /> 
                </View>
                </View>
        <View>
          <Text style={styles.title2}>
            Certificazione
          </Text>
          <Text style={styles.footer}>
          I dati sono certificati dal sistema informativo della Sezione AIA di Pinerolo e nella loro integrità sono salvati nel cloud. Il presente documento è stato generato ed aggiornato in data {moment().format('DD/MM/YYYY [alle] HH:mm')}.
          </Text>
        </View>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
      </Page>
            )
          })}
        
    </Document>
  )
  const DownloadPdf = () => {
    return useMemo(
      () => (
        <PDFDownloadLink className='btn btn-success' document={<MyDoc />} fileName={`RISPOSTE.${this.props.match.params.id}.${moment().format('DD/MM/YYYY-HH:mm')}.pdf`}>
          {({ loading }) => (loading ? 'Caricamento...' : 'Esporta')}
        </PDFDownloadLink>
      ),
      [],
    )
  }
    if (risposte && quiz && MyDoc) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-dumbbell'></i> Lista risposte {this.props.match.params.id}
              </h2>
            </div>
            <div className='col-md-6'>
              <div className='float-right'>
                <Link to='/quiz' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alla
                  lista quiz
                </Link>
                <DownloadPdf />
              </div>
            </div>
          </div>
          <hr />
          <div className='table-responsive'>
            <table className='table table-striped table-bordered table-sm text-center'>
              <thead className='thead-inverse'>
                <tr>
                <th>Utente</th>
                  <th>Corrette</th>
                  <th>Sbagliate</th>
                  <th>Percentuale</th>
                  <th>Data</th>
                  <th>Cod. Certificato</th>
                </tr>
              </thead>
              <tbody>
                {risposte.map((risposta) => (
                  <tr key={risposta.id}>
                    <td>{users.map((user) => (user.id === risposta.utente ? user.firstName+' '+user.lastName : null))}</td>
                    <td>{risposta.corrette}</td> 
                    <td>{risposta.sbagliate}</td>  
                    <td>{parseFloat(risposta.corrette/(risposta.corrette+risposta.sbagliate)*100).toFixed(2)}%</td>     
                    <td>{moment(risposta.data).format('DD/MM/YYYY - HH:mm')}</td>
                    <td><span className="badge badge-success">{risposta.id}</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}
AnswerList.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "risposte",
      where: ['quiz','==',props.match.params.id]
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "quiz",
      where: ['codice','==',props.match.params.id]
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "utenti",
      storeAs: "users",
    },
  ]),
  connect((state, props) => ({
    risposte: state.firestore.ordered.risposte,
    users: state.firestore.ordered.users,
    quiz: state.firestore.ordered.quiz && state.firestore.ordered.quiz[0],
    profile: state.firebase.profile,
  }))
)(AnswerList);
