import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";

class VideoList extends Component {
  render() {
    const { video, profile } = this.props;
    if (video) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-video'></i> Lista video
              </h2>
            </div>
            <div className='col-md-6'>
              <div className='float-right'>
                <Link to='/video' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alle
                  categorie
                </Link>
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            {video.map((v) => (
              <div className='col-md-4' key={v.id}>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>
                      {v.tipo} - {v.codice}{" "}
                      {profile.role === "admin" ? (
                        <Link
                          to={"/video/edit/" + v.id}
                          className='btn btn-warning btn-sm'
                          key={v.id}
                        >
                          <i className='fa fa-pen'></i>
                        </Link>
                      ) : null}
                    </h5>
                    <Link
                      to={"/video/" + v.fonte + "/" + v.id}
                      className='btn btn-primary'
                      key={v.id}
                    >
                      Vai al video
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "video",
      where: [
        "serie",
        "==",
        props.match.params.start + "-" + props.match.params.end,
      ],
      orderBy: "codice",
    },
  ]),
  connect((state, props) => ({
    video: state.firestore.ordered.video,
    profile: state.firebase.profile,
  }))
)(VideoList);
