import React from "react";
import { Link } from "react-router-dom";

function VideoList2() {
  return (
    <div>
      <div className='row'>
        <div className='col-md-6'>
          <h2>
            {" "}
            <i className='fas fa-video'></i> Lista categorie video
          </h2>
        </div>
        <div className='col-md-6'>
          <div className='float-right'>
            <Link to='/' className='btn btn-link'>
              <i className='fas fa-arrow-circle-left' /> Indietro alla dashboard
            </Link>
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-md-4'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>UEFA 2020.1</h4>
              <div className='list-group'>
                <Link
                  to='/video/uefa20201/a-g'
                  className='list-group-item list-group-item-action'
                >
                  Decision making
                </Link>
                <Link
                  to='/video/uefa20201/h-k'
                  className='list-group-item list-group-item-action'
                >
                  Management issues
                </Link>
                <Link
                  to='/video/uefa20201/l-l'
                  className='list-group-item list-group-item-action'
                >
                  Offside
                </Link>
                <Link
                  to='/video/uefa20201/m-m'
                  className='list-group-item list-group-item-action'
                >
                  Teamwork
                </Link>
                <Link
                  to='/video/uefa20201/n-n'
                  className='list-group-item list-group-item-action'
                >
                  Laws of the game
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoList2;
