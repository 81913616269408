import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import moment from "moment";
import "moment/locale/it";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

moment.locale("it");

class UserDetails extends Component {
  state = {};

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onDeleteClick = () => {
    const { user, firestore, history } = this.props;
    firestore
      .delete({ collection: "utenti", doc: user.id })
      .then(history.push("/"));
  };

  onPasswordChangeClick = () => {
    const { firebase, user, notifyUser } = this.props;
    firebase
      .auth()
      .sendPasswordResetEmail(user.email)
      .then(() => {
        notifyUser(
          "Le istruzioni per cambiare la password sono state corrrettamente inviate alla tua email " +
            user.email,
          "success"
        );
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .catch((e) => {
        notifyUser("Errore. Riprovare o contattare il webmaster.", "error");
        setTimeout(() => notifyUser(null, null), 4000);
      });
  };

  mediaVotiOa = () => {
    const { matches } = this.props;
    const voti = matches.map((match) => {
      if (match.oa === true && match.voto !== undefined) {
        return match.voto;
      }
      return null;
    });
    const filteredVoti = voti.filter(function (el) {
      return el != null;
    });
    const sum = filteredVoti.reduce((a, b) => a + b, 0);
    const avg = sum / filteredVoti.length || 0;
    return parseFloat(avg).toFixed(3);
  };
  mediaVotiOt = () => {
    const { matches } = this.props;
    const voti = matches.map((match) => {
      if (match.ot === true && match.voto !== undefined) {
        return match.voto;
      }
      return null;
    });
    const filteredVoti = voti.filter(function (el) {
      return el != null;
    });
    const sum = filteredVoti.reduce((a, b) => a + b, 0);
    const avg = sum / filteredVoti.length || 0;
    return parseFloat(avg).toFixed(3);
  };
  mediaVoti = () => {
    const medie = [
      parseFloat(this.mediaVotiOa()),
      parseFloat(this.mediaVotiOt()),
    ];
    const filteredVoti = medie.filter(function (el) {
      return el != null;
    });
    const sum = filteredVoti.reduce((a, b) => a + b, 0);
    return medie[0] !== 0 && medie[1] !== 0
      ? parseFloat(sum / 2).toFixed(3)
      : "N/D";
  };
  totaleVisionature = () => {
    const { matches } = this.props;
    const totale = [];
    matches.map((match) =>
      match.ot === true || (match.oa === true && match.voto !== undefined)
        ? totale.push(match.id)
        : null
    );
    return totale.length;
  };
  partiteArbitrate = () => {
    const { matches } = this.props;
    const giocate = [];
    matches.map((match) =>
      match.status === "giocata" ? giocate.push(match.id) : null
    );
    return giocate.length;
  };
  partiteRimborsate = () => {
    const { matches } = this.props;
    const rimborsate = [];
    matches.map((match) =>
      match.statusRimborso === "rimborsata"
        ? rimborsate.push(match.rimborso)
        : null
    );
    return rimborsate.length + " - € " + rimborsate.reduce((a, b) => a + b, 0);
  };
  totaleRimborsi = () => {
    const { matches } = this.props;
    const totaleRimborsi = [];
    matches.map((match) => totaleRimborsi.push(match.rimborso));
    return totaleRimborsi.reduce((a, b) => a + b, 0);
  };
  partiteDaRimborsare = () => {
    const { matches } = this.props;
    const rimborsate = [];
    matches.map((match) =>
      match.statusRimborso === "non rimborsata"
        ? rimborsate.push(match.rimborso)
        : null
    );
    return rimborsate.length + " - € " + rimborsate.reduce((a, b) => a + b, 0);
  };
  ammonizioni = (param) => {
    const { matches } = this.props;
    const ammonizioni = [];
    matches.map((match) =>
      ammonizioni.push(
        match.ammonizioni.home[param],
        match.ammonizioni.away[param]
      )
    );
    return ammonizioni.reduce((a, b) => a + b, 0);
  };
  ammonizioniTotali = () => {
    const { matches } = this.props;
    const ammonizioni = [];
    matches.map((match) =>
      ammonizioni.push(
        match.ammonizioni.home.proteste,
        match.ammonizioni.home.fallo,
        match.ammonizioni.home.antisportivo,
        match.ammonizioni.home.altro,
        match.ammonizioni.away.proteste,
        match.ammonizioni.away.fallo,
        match.ammonizioni.away.antisportivo,
        match.ammonizioni.away.altro
      )
    );
    return ammonizioni.reduce((a, b) => a + b, 0);
  };
  mediaAmmonizioni = () => {
    const { matches } = this.props;
    return parseFloat(this.ammonizioniTotali() / matches.length).toFixed(2);
  };
  espulsioni = (param) => {
    const { matches } = this.props;
    const espulsioni = [];
    matches.map((match) =>
      espulsioni.push(
        match.espulsioni.home[param],
        match.espulsioni.away[param]
      )
    );
    return espulsioni.reduce((a, b) => a + b, 0);
  };

  espulsioniTotali = () => {
    const { matches } = this.props;
    const espulsioni = [];
    matches.map((match) =>
      espulsioni.push(
        match.espulsioni.home.proteste,
        match.espulsioni.home.fallo,
        match.espulsioni.home.condotta,
        match.espulsioni.home.dogso,
        match.espulsioni.home.dpa,
        match.espulsioni.home.altro,
        match.espulsioni.away.proteste,
        match.espulsioni.away.fallo,
        match.espulsioni.away.dogso,
        match.espulsioni.away.dpa,
        match.espulsioni.away.condotta,
        match.espulsioni.away.altro
      )
    );
    return espulsioni.reduce((a, b) => a + b, 0);
  };
  mediaEspulsioni = () => {
    const { matches } = this.props;
    return parseFloat(this.espulsioniTotali() / matches.length).toFixed(2);
  };
  render() {
    const { user, matches, rto, trainings, profile, auth } = this.props;
    const { message, messageType } = this.props.notify;
    if (user && matches && rto && trainings) {
      if (profile.role === "admin") {
        return (
          <div>
            {message ? (
              <Alert message={message} messageType={messageType} />
            ) : null}
            <div className='row'>
              <div className='col-md-6'>
                <Link to='/' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alla
                  dashboard
                </Link>
              </div>
              <div className='col-md-6'>
                <div className='btn-group float-right'>
                  <Link to={`/user/edit/${user.id}`} className='btn btn-dark'>
                    Modifica
                  </Link>
                  <button
                    onClick={this.onDeleteClick}
                    className='btn btn-danger'
                  >
                    Elimina
                  </button>
                  <button
                    onClick={this.onPasswordChangeClick}
                    className='btn btn-warning'
                  >
                    Cambia Password
                  </button>
                </div>
              </div>
            </div>

            <hr />
            <div className='card'>
              <h3 className='card-header'>
                [{user.meccanografico}] {user.firstName} {user.lastName}
              </h3>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-7'>
                    <h4>Informazioni</h4>
                    <ul className='list-group'>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        ID Utente:{" "}
                        <span className='badge badge-secondary'>{user.id}</span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Email:{" "}
                        <span className='badge badge-secondary'>
                          {user.email}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Telefono:{" "}
                        <span className='badge badge-secondary'>
                          {user.phone}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Organo Tecnico:{" "}
                        <span className='badge badge-secondary'>{user.ot}</span>
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-5'>
                    <h4>Media voti</h4>
                    <ul className='list-group'>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Media OA:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.mediaVotiOa()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Media OT:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.mediaVotiOt()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Media Generale:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.mediaVoti()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Totale visionature:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.totaleVisionature()}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-md-6'>
                    <h4>Statistiche partite</h4>
                    <ul className='list-group'>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Partite arbitrate:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.partiteArbitrate()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Partite rimborsate:{" "}
                        <span className='badge badge-success badge-pill'>
                          {this.partiteRimborsate()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Partite da rimborsate:{" "}
                        <span className='badge badge-danger badge-pill'>
                          {this.partiteDaRimborsare()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Totale rimborsi:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.totaleRimborsi()} €
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <h4>Statistiche provvedimenti</h4>

                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='list-group'>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Ammonizioni totali:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.ammonizioniTotali()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Media ammonizioni/partita:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.mediaAmmonizioni()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Ammonizioni proteste:{" "}
                        <span className='badge badge-warning badge-pill'>
                          {this.ammonizioni("proteste")}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Ammonizioni falli:{" "}
                        <span className='badge badge-warning badge-pill'>
                          {this.ammonizioni("fallo")}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Ammonizioni antisportivi:{" "}
                        <span className='badge badge-warning badge-pill'>
                          {this.ammonizioni("antisportivo")}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Ammonizioni altro:{" "}
                        <span className='badge badge-warning badge-pill'>
                          {this.ammonizioni("altro")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='list-group'>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Espulsioni totali:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.espulsioniTotali()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Media espulsioni/partita:{" "}
                        <span className='badge badge-primary badge-pill'>
                          {this.mediaEspulsioni()}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Espulsioni proteste:{" "}
                        <span className='badge badge-danger badge-pill'>
                          {this.espulsioni("proteste")}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Espulsioni gravi falli:{" "}
                        <span className='badge badge-danger badge-pill'>
                          {this.espulsioni("fallo")}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Espulsioni condotte violente:{" "}
                        <span className='badge badge-danger badge-pill'>
                          {this.espulsioni("condotta")}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Espulsioni DOGSO:{" "}
                        <span className='badge badge-danger badge-pill'>
                          {this.espulsioni("dogso")}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Espulsioni DPA:{" "}
                        <span className='badge badge-danger badge-pill'>
                          {this.espulsioni("dpa")}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Espulsioni altro:{" "}
                        <span className='badge badge-danger badge-pill'>
                          {this.espulsioni("altro")}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-md-6'>
                    <h4>RTO ({rto.length})</h4>
                    <div className='table-responsive'>
                      <table className='table table-striped table-bordered table-sm'>
                        <thead>
                          <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Data</th>
                            <th scope='col'>Presente</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rto.map((rto) => (
                            <tr key={rto.id}>
                              <td>{rto.numero}</td>
                              <td>{moment(rto.data.toDate()).format("L")}</td>
                              <td>{rto.presenti ? "Si" : "No"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <h4>Allenamenti ({trainings.length})</h4>
                    <div className='table-responsive'>
                      <table className='table table-striped table-bordered table-sm'>
                        <thead>
                          <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Data</th>
                            <th scope='col'>Presente</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trainings.map((training) => (
                            <tr key={training.id}>
                              <td>{training.numero}</td>
                              <td>
                                {moment(training.data.toDate()).format("L")}
                              </td>
                              <td>{training.presenti ? "Si" : "No"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr />

                <h3>Partite</h3>
                <div className='table-responsive'>
                  <table className='table table-striped table-bordered table-sm text-center'>
                    <thead>
                      <tr>
                        <th scope='col'>Data</th>
                        <th scope='col'>Ora</th>
                        <th scope='col'>Sq. ospitante</th>
                        <th scope='col'>Sq. ospitata</th>
                        <th scope='col'>Cat.</th>
                        <th scope='col'>Risultato</th>
                        <th scope='col'>OA</th>
                        <th scope='col'>OT</th>
                        <th scope='col'>Voto</th>
                        <th scope='col'>Stato</th>
                        <th scope='col'>Rimborso</th>
                        <th scope='col'>Rimborsata</th>
                        <th scope='col'>Modifica</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matches.map((match) => (
                        <tr key={match.id}>
                          <td>
                            {moment(match.data.toDate()).format("DD/MM/YYYY")}
                          </td>
                          <td>{moment(match.data.toDate()).format("HH:mm")}</td>
                          <td>{match.partita.home}</td>
                          <td>{match.partita.away}</td>
                          <td>{match.categoria}</td>
                          <td>
                            {isNaN(match.risultato.home) !== true
                              ? match.risultato.home + " : "
                              : ""}

                            {isNaN(match.risultato.away) !== true
                              ? match.risultato.away
                              : "-"}
                          </td>
                          <td>{match.oa ? "Si" : "No"}</td>
                          <td>{match.ot ? "Si" : "No"}</td>
                          <td>
                            {match.oa || match.ot
                              ? parseFloat(match.voto).toFixed(2)
                              : "-"}
                          </td>
                          <td>{match.status}</td>
                          <td>€ {parseFloat(match.rimborso)}</td>
                          <td>
                            {match.statusRimborso === "rimborsata"
                              ? "Si"
                              : "No"}
                          </td>
                          <td>
                            <div className='btn-group'>
                              <Link
                                to={`/match/edit/${match.id}`}
                                className='btn btn-warning btn-sm'
                              >
                                <i className='fas fa-pen' />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        if (auth.uid === this.props.match.params.id) {
          return (
            <div>
              {message ? (
                <Alert message={message} messageType={messageType} />
              ) : null}
              <div className='row'>
                <div className='col-md-6'>
                  <Link to='/' className='btn btn-link'>
                    <i className='fas fa-arrow-circle-left' /> Indietro alla
                    dashboard
                  </Link>
                </div>
                <div className='col-md-6'>
                  <div className='btn-group float-right'>
                    <button
                      onClick={this.onPasswordChangeClick}
                      className='btn btn-warning'
                    >
                      Cambia Password
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div className='card'>
                <h3 className='card-header'>
                  [{user.meccanografico}] {user.firstName} {user.lastName}
                </h3>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-7'>
                      <h4>Informazioni</h4>
                      <ul className='list-group'>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          ID Utente:{" "}
                          <span className='badge badge-secondary'>
                            {user.id}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Email:{" "}
                          <span className='badge badge-secondary'>
                            {user.email}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Telefono:{" "}
                          <span className='badge badge-secondary'>
                            {user.phone}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Organo Tecnico:{" "}
                          <span className='badge badge-secondary'>
                            {user.ot}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className='col-md-5'>
                      <h4>Media voti</h4>
                      <ul className='list-group'>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Media OA:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.mediaVotiOa()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Media OT:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.mediaVotiOt()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Media Generale:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.mediaVoti()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Totale visionature:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.totaleVisionature()}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <div className='row'>
                    <div className='col-md-6'>
                      <h4>Statistiche partite</h4>
                      <ul className='list-group'>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Partite arbitrate:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.partiteArbitrate()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Partite rimborsate:{" "}
                          <span className='badge badge-success badge-pill'>
                            {this.partiteRimborsate()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Partite da rimborsate:{" "}
                          <span className='badge badge-danger badge-pill'>
                            {this.partiteDaRimborsare()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Totale rimborsi:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.totaleRimborsi()} €
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <h4>Statistiche provvedimenti</h4>

                  <div className='row'>
                    <div className='col-md-6'>
                      <ul className='list-group'>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Ammonizioni totali:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.ammonizioniTotali()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Media ammonizioni/partita:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.mediaAmmonizioni()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Ammonizioni proteste:{" "}
                          <span className='badge badge-warning badge-pill'>
                            {this.ammonizioni("proteste")}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Ammonizioni falli:{" "}
                          <span className='badge badge-warning badge-pill'>
                            {this.ammonizioni("fallo")}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Ammonizioni antisportivi:{" "}
                          <span className='badge badge-warning badge-pill'>
                            {this.ammonizioni("antisportivo")}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Ammonizioni altro:{" "}
                          <span className='badge badge-warning badge-pill'>
                            {this.ammonizioni("altro")}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className='col-md-6'>
                      <ul className='list-group'>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Espulsioni totali:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.espulsioniTotali()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Media espulsioni/partita:{" "}
                          <span className='badge badge-primary badge-pill'>
                            {this.mediaEspulsioni()}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Espulsioni proteste:{" "}
                          <span className='badge badge-danger badge-pill'>
                            {this.espulsioni("proteste")}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Espulsioni gravi falli:{" "}
                          <span className='badge badge-danger badge-pill'>
                            {this.espulsioni("fallo")}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Espulsioni condotte violente:{" "}
                          <span className='badge badge-danger badge-pill'>
                            {this.espulsioni("condotta")}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Espulsioni DOGSO:{" "}
                          <span className='badge badge-danger badge-pill'>
                            {this.espulsioni("dogso")}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Espulsioni DPA:{" "}
                          <span className='badge badge-danger badge-pill'>
                            {this.espulsioni("dpa")}
                          </span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                          Espulsioni altro:{" "}
                          <span className='badge badge-danger badge-pill'>
                            {this.espulsioni("altro")}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <div className='row'>
                    <div className='col-md-6'>
                      <h4>RTO ({rto.length})</h4>
                      <div className='table-responsive'>
                        <table className='table table-striped table-bordered table-sm'>
                          <thead>
                            <tr>
                              <th scope='col'>#</th>
                              <th scope='col'>Data</th>
                              <th scope='col'>Presente</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rto.map((rto) => (
                              <tr key={rto.id}>
                                <td>{rto.numero}</td>
                                <td>{moment(rto.data.toDate()).format("L")}</td>
                                <td>{rto.presenti ? "Si" : "No"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <h4>Allenamenti ({trainings.length})</h4>
                      <div className='table-responsive'>
                        <table className='table table-striped table-bordered table-sm'>
                          <thead>
                            <tr>
                              <th scope='col'>#</th>
                              <th scope='col'>Data</th>
                              <th scope='col'>Presente</th>
                            </tr>
                          </thead>
                          <tbody>
                            {trainings.map((training) => (
                              <tr key={training.id}>
                                <td>{training.numero}</td>
                                <td>
                                  {moment(training.data.toDate()).format("L")}
                                </td>
                                <td>{training.presenti ? "Si" : "No"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <h3>Partite</h3>
                  <div className='table-responsive'>
                    <table className='table table-striped table-bordered table-sm text-center'>
                      <thead>
                        <tr>
                          <th scope='col'>Data</th>
                          <th scope='col'>Ora</th>
                          <th scope='col'>Sq. ospitante</th>
                          <th scope='col'>Sq. ospitata</th>
                          <th scope='col'>Cat.</th>
                          <th scope='col'>Risultato</th>
                          <th scope='col'>OA</th>
                          <th scope='col'>OT</th>
                          <th scope='col'>Voto</th>
                          <th scope='col'>Stato</th>
                          <th scope='col'>Rimborso</th>
                          <th scope='col'>Rimborsata</th>
                          <th scope='col'>Modifica</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matches.map((match) => (
                          <tr key={match.id}>
                            <td>
                              {moment(match.data.toDate()).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              {moment(match.data.toDate()).format("HH:mm")}
                            </td>
                            <td>
                              {match.partita.home} - {match.partita.away}
                            </td>
                            <td>{match.categoria}</td>
                            <td>
                              {isNaN(match.risultato.home) !== true
                                ? match.risultato.home + " : "
                                : ""}

                              {isNaN(match.risultato.away) !== true
                                ? match.risultato.away
                                : "-"}
                            </td>
                            <td>{match.oa ? "Si" : "No"}</td>
                            <td>{match.ot ? "Si" : "No"}</td>
                            <td>
                              {match.oa || match.ot
                                ? parseFloat(match.voto).toFixed(2)
                                : "-"}
                            </td>
                            <td>{match.status}</td>
                            <td>€ {parseFloat(match.rimborso)}</td>
                            <td>
                              {match.statusRimborso === "rimborsata"
                                ? "Si"
                                : "No"}
                            </td>
                            <td>
                              <div className='btn-group'>
                                <Link
                                  to={`/match/edit/${match.id}`}
                                  className='btn btn-warning btn-sm'
                                >
                                  <i className='fas fa-pen' />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <h1 className='text-center text-danger mt-5'>
              Autorizzazioni insufficienti!
            </h1>
          );
        }
      }
    } else {
      return <Spinner />;
    }
  }
}

UserDetails.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "utenti",
      storeAs: "user",
      doc: props.match.params.id,
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "partite",
      storeAs: "matches",
      where: ["utente", "==", props.match.params.id],
      orderBy: "data",
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "rto",
      storeAs: "rto",
      where: ["presenti", "array-contains", props.match.params.id],
      orderBy: "numero",
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "allenamenti",
      storeAs: "trainings",
      where: ["presenti", "array-contains", props.match.params.id],
      orderBy: "numero",
    },
  ]),
  firebaseConnect(),

  connect(
    (state, props) => ({
      user: state.firestore.ordered.user && state.firestore.ordered.user[0],
      matches: state.firestore.ordered.matches,
      rto: state.firestore.ordered.rto,
      trainings: state.firestore.ordered.trainings,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(UserDetails);
