import React, { Component } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

class AddMatch extends Component {
  state = {
    categoria: "",
    partita: { home: "", away: "" },
    risultato: { home: "", away: "" },
    data: "",
    ot: "",
    oa: "",
    voto: "",
    utente: "",
    rimborso: "",
    status: "",
    statusRimborso: "",
  };
  onSubmit = (e) => {
    e.preventDefault();
    const newMatch = {
      categoria: this.state.categoria,
      partita: { home: this.state.home, away: this.state.away },
      risultato: {
        home:
          this.state.homeResult !== null || undefined
            ? parseFloat(this.state.homeResult)
            : null,
        away:
          this.state.awayResult !== null || undefined
            ? parseFloat(this.state.awayResult)
            : null,
      },
      data: new Date(this.state.data),
      ot: this.state.ot === "true" ? true : false,
      oa: this.state.oa === "true" ? true : false,
      voto: parseFloat(this.state.voto),
      utente: this.state.utente,
      status: this.state.status,
      statusRimborso: this.state.statusRimborso,
      rimborso: parseFloat(this.state.rimborso),
      ammonizioni: {
        home: {
          fallo: parseFloat(0),
          proteste: parseFloat(0),
          antisportivo: parseFloat(0),
          altro: parseFloat(0),
        },
        away: {
          fallo: parseFloat(0),
          proteste: parseFloat(0),
          antisportivo: parseFloat(0),
          altro: parseFloat(0),
        },
      },
      espulsioni: {
        home: {
          fallo: parseFloat(0),
          condotta: parseFloat(0),
          altro: parseFloat(0),
          proteste: parseFloat(0),
          dogso: parseFloat(0),
          dpa: parseFloat(0),
        },
        away: {
          fallo: parseFloat(0),
          condotta: parseFloat(0),
          altro: parseFloat(0),
          proteste: parseFloat(0),
          dogso: parseFloat(0),
          dpa: parseFloat(0),
        },
      },
    };
    const { firestore, history, notifyUser } = this.props;
    firestore
      .add({ collection: "partite" }, newMatch)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { users, profile, auth } = this.props;
    const { message, messageType } = this.props.notify;
    if (users) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                dashboard
              </Link>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <div className='card'>
            <h5 className='card-header'>Aggiungi Partita</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='form-group'>
                      <label htmlFor='utente'>Associato</label>
                      <select
                        className='form-control'
                        name='utente'
                        id='utente'
                        onChange={this.onChange}
                        value={this.state.utente}
                        required
                      >
                        <option value='default'>Seleziona Associato...</option>
                        {profile.role === "admin" ? (
                          users.map((user) => (
                            <option key={user.id} value={user.id}>
                              [{user.meccanografico}] {user.firstName}{" "}
                              {user.lastName}
                            </option>
                          ))
                        ) : (
                          <option value={auth.uid}>
                            [{profile.meccanografico}] {profile.firstName}{" "}
                            {profile.lastName}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='data'>Data</label>
                      <input
                        type='datetime-local'
                        className='form-control'
                        name='data'
                        id='data'
                        required
                        onChange={this.onChange}
                        value={
                          this.state.data
                            ? this.state.data
                            : new Date(Date.now())
                                .toISOString()
                                .slice(0, -1)
                                .slice(0, 16)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='categoria'>Categoria</label>
                      <select
                        className='form-control'
                        name='categoria'
                        id='categoria'
                        value={this.state.categoria}
                        onChange={this.onChange}
                      >
                        <option value='default'>Seleziona Categoria...</option>
                        <option value='GIR'>
                          GIR - Giovanissimi Regionali
                        </option>
                        <option value='GIP'>
                          GIP - Giovanissimi Provinciali
                        </option>
                        <option value='GBP'>
                          GBP - Giovanissimi Fascia B Provinciali
                        </option>
                        <option value='GRB'>
                          GRB - Giovanissimi Fascia B Regionali
                        </option>
                        <option value='ALR'>ALR - Allievi Regionali</option>
                        <option value='ALP'>ALP - Allievi Provinciali</option>
                        <option value='ALB'>
                          ALB - Allievi Fascia B Provinciali
                        </option>
                        <option value='ARB'>
                          ARB - Allievi Fascia B Regionali
                        </option>
                        <option value='JUP'>JUP - Juniores Provinciali</option>
                        <option value='JUR'>JUR - Juniores Regionali</option>
                        <option value='SEC'>SEC - Seconda Categoria</option>
                        <option value='TER'>TER - Terza Categoria</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='home'>Squadra Ospitante</label>
                      <input
                        type='text'
                        className='form-control'
                        name='home'
                        id='home'
                        required
                        onChange={this.onChange}
                        value={this.state.home || ""}
                        placeholder='Inserisci squadra in casa'
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='away'>Squadra Ospitata</label>
                      <input
                        type='text'
                        className='form-control'
                        name='away'
                        id='away'
                        required
                        onChange={this.onChange}
                        value={this.state.away || ""}
                        placeholder='Inserisci squadra in trasferta'
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <label htmlFor='risultato'>Risultato</label>
                    <div className='row' id='risultato'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <input
                            type='number'
                            className='form-control'
                            name='homeResult'
                            id='homeResult'
                            maxLength='2'
                            placeholder='Casa'
                            value={this.state.homeResult || ""}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <input
                            type='number'
                            className='form-control'
                            name='awayResult'
                            id='awayResult'
                            maxLength='2'
                            placeholder='Ospiti'
                            value={this.state.awayResult || ""}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='oa'>OA</label>
                      <select
                        className='form-control'
                        name='oa'
                        id='oa'
                        value={this.state.oa}
                        onChange={this.onChange}
                      >
                        <option value='default'>Seleziona Opzione...</option>
                        <option value={Boolean(false)}>No</option>
                        <option value={Boolean(true)}>Sì</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='ot'>OT</label>
                      <select
                        className='form-control'
                        name='ot'
                        id='ot'
                        value={this.state.ot}
                        onChange={this.onChange}
                      >
                        <option value='default'>Seleziona Opzione...</option>
                        <option value={Boolean(false)}>No</option>
                        <option value={Boolean(true)}>Sì</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='voto'>Voto</label>
                      <select
                        className='form-control'
                        name='voto'
                        id='voto'
                        value={this.state.voto}
                        onChange={this.onChange}
                      >
                        <option value='default'>Seleziona Voto...</option>
                        <option value={null}>No Voto</option>
                        <option value='8.20'>8.20</option>
                        <option value='8.30'>8.30</option>
                        <option value='8.40'>8.40</option>
                        <option value='8.50'>8.50</option>
                        <option value='8.60'>8.60</option>
                        <option value='8.70'>8.70</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='status'>Status Partita</label>
                      <select
                        className='form-control'
                        name='status'
                        id='status'
                        value={this.state.status}
                        onChange={this.onChange}
                      >
                        <option value='default'>Seleziona status...</option>
                        <option value='Giocata'>Giocata</option>
                        <option value='Rinviata'>Rinviata</option>
                        <option value='Sospesa'>Sospesa</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='rimborso'>Rimborso</label>
                      <input
                        type='number'
                        className='form-control'
                        name='rimborso'
                        id='rimborso'
                        value={this.state.rimborso}
                        placeholder='Inserisci rimborso in €'
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='statusRimborso'>Status Rimborso</label>
                      <select
                        className='form-control'
                        name='statusRimborso'
                        id='statusRimborso'
                        value={this.state.statusRimborso}
                        onChange={this.onChange}
                      >
                        <option value='default'>Seleziona status...</option>
                        <option value='rimborsata'>Rimborsata</option>
                        <option value='non rimborsata'>Non rimborsata</option>
                      </select>
                    </div>
                  </div>
                </div>

                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}
export default compose(
  firestoreConnect([{ collection: "utenti" }]),
  connect(
    (state, props) => ({
      users: state.firestore.ordered.utenti,
      profile: state.firebase.profile,
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(AddMatch);
