import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import PropTypes from "prop-types";

class Sidebar extends Component {
  state = {
    isAuthenticated: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;
    if (auth.uid) {
      return { isAuthenticated: true };
    } else {
      return { isAuthenticated: false };
    }
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    const { firebase } = this.props;
    firebase.logout();
  };

  render() {
    const { profile } = this.props;
    if (profile.role === "admin") {
      return (
        <div>
          <div className='dropdown' style={{ marginBottom: "8px" }}>
            <button
              className='btn btn-success btn-block dropdown-toggle'
              type='button'
              id='drop-add'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <i className='fas fa-plus'></i> Aggiungi
            </button>
            <div className='dropdown-menu' aria-labelledby='drop-add'>
              <Link to='/user/add' className='dropdown-item'>
                Associato
              </Link>
              <Link to='/match/add' className='dropdown-item'>
                Partita
              </Link>
              <Link to='/rto/add' className='dropdown-item'>
                RTO
              </Link>
              <Link to='/training/add' className='dropdown-item'>
                Allenamento
              </Link>
              <Link to='/fields/add' className='dropdown-item'>
                Campo
              </Link>
              <Link to='/goals/add' className='dropdown-item'>
                Obbiettivo
              </Link>
              <Link to='/video/add' className='dropdown-item'>
                Video
              </Link>
            </div>
          </div>
          <div className='dropdown' style={{ marginBottom: "8px" }}>
            <button
              className='btn btn-danger btn-block dropdown-toggle'
              type='button'
              id='drop-add'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <i className='fas fa-plus'></i> Quiz
            </button>
            <div className='dropdown-menu' aria-labelledby='drop-add'>
              <Link to='/quiz/add' className='dropdown-item'>
                Aggiungi quiz
              </Link>
              <Link to='/quiz/question/add' className='dropdown-item'>
                Aggiungi domanda
              </Link>
              <Link to='/quiz' className='dropdown-item'>
                Lista quiz
              </Link>
              <Link to='/quiz/questions' className='dropdown-item'>
                Lista domande
              </Link>
            </div>
          </div>
          <div className='dropdown' style={{ marginBottom: "8px" }}>
            <button
              className='btn btn-primary btn-block dropdown-toggle'
              type='button'
              id='drop-list'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <i className='fas fa-list'></i> Liste
            </button>
            <div className='dropdown-menu' aria-labelledby='drop-list'>
              <Link to='/rto/list' className='dropdown-item'>
                RTO
              </Link>
              <Link to='/training/list' className='dropdown-item'>
                Allenamenti
              </Link>
              <Link to='/fields/list' className='dropdown-item'>
                Campi
              </Link>
              <Link to='/goals/scoreboard' className='dropdown-item'>
                Scoreboard
              </Link>
            </div>
          </div>
          <Link to='/video' className='btn btn-secondary btn-block'>
            Video ST
          </Link>
        </div>
      );
    } else {
      return (
        <div>
          <div className='dropdown' style={{ marginBottom: "8px" }}>
            <button
              className='btn btn-success btn-block dropdown-toggle'
              type='button'
              id='drop-add'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <i className='fas fa-plus'></i> Aggiungi
            </button>
            <div className='dropdown-menu' aria-labelledby='drop-add'>
              <Link to='/match/add' className='dropdown-item'>
                Partita
              </Link>
              <Link to='/fields/add' className='dropdown-item'>
                Campo
              </Link>
            </div>
          </div>
          <div className='dropdown' style={{ marginBottom: "8px" }}>
            <button
              className='btn btn-primary btn-block dropdown-toggle'
              type='button'
              id='drop-list'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <i className='fas fa-list'></i> Liste
            </button>
            <div className='dropdown-menu' aria-labelledby='drop-list'>
              <Link to='/rto/list' className='dropdown-item'>
                RTO
              </Link>
              <Link to='/training/list' className='dropdown-item'>
                Allenamenti
              </Link>
              <Link to='/fields/list' className='dropdown-item'>
                Campi
              </Link>
              <Link to='/goals/scoreboard' className='dropdown-item'>
                Scoreboard
              </Link>
            </div>
          </div>
          <div className='dropdown' style={{ marginBottom: "8px" }}>
            <button
              className='btn btn-danger btn-block dropdown-toggle'
              type='button'
              id='drop-add'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <i className='fas fa-plus'></i> Quiz
            </button>
            <div className='dropdown-menu' aria-labelledby='drop-add'>
              <Link to='/quiz' className='dropdown-item'>
                Lista quiz
              </Link>
            </div>
          </div>
          <Link to='/video' className='btn btn-secondary btn-block'>
            <i className='fas fa-list'></i> Video ST
          </Link>
        </div>
      );
    }
  }
}
Sidebar.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }))
)(Sidebar);
