import React, { Component } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

class EditQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numero: "",
      regola: "",
      testo: "",
      inputs: 2,
      tipo: "",
      r1: {
        risposta: "",
        testo: "",
        corretta: "",
      },
      r2: {
        risposta: "",
        testo: "",
        corretta: "",
      },
      r3: {
        risposta: null,
        testo: null,
        corretta: null
      },
      r4: {
        risposta: null,
        testo: null,
        corretta: null
      },
    };
  }
  onSubmit = (e) => {
    const { firestore, history, notifyUser, domanda } = this.props;
    const risposte = [
      {
        corretta:
         this.state.r1.corretta ? this.state.r1.corretta.toLowerCase() === "true" ? true : false : domanda.risposte[0].corretta,
        risposta: this.state.r1.risposta ? this.state.r1.risposta : domanda.risposte[0].risposta,
        testo: this.state.r1.testo ? this.state.r1.testo : domanda.risposte[0].testo,
      },
      {
        corretta:
         this.state.r2.corretta ? this.state.r2.corretta.toLowerCase() === "true" ? true : false : domanda.risposte[1].corretta,
        risposta: this.state.r2.risposta ? this.state.r2.risposta : domanda.risposte[1].risposta,
        testo: this.state.r2.testo ? this.state.r2.testo : domanda.risposte[1].testo,
      },
      this.state.r3.testo !== '' && this.state.r3.risposta !== '' && this.state.r3.corretta !== 'default' ? {
      corretta: this.state.r3.corretta ? this.state.r3.corretta.toLowerCase() === "true" ? true : false : domanda.risposte[2] ? domanda.risposte[2].corretta : null,
       risposta: this.state.r3.risposta ? this.state.r3.risposta : domanda.risposte[2] ? domanda.risposte[2].risposta : null,
       testo: this.state.r3.testo ? this.state.r3.testo : domanda.risposte[2] ? domanda.risposte[2].testo : null,
      } : null,
      this.state.r4.testo !== '' && this.state.r4.risposta !== '' && this.state.r4.corretta !== 'default' ? {
        corretta: this.state.r4.corretta ? this.state.r4.corretta.toLowerCase() === "true" ? true : false : domanda.risposte[3] ? domanda.risposte[3].corretta : null,
       risposta: this.state.r4.risposta ? this.state.r4.risposta : domanda.risposte[3] ? domanda.risposte[3].risposta : null,
       testo: this.state.r4.testo ? this.state.r4.testo : domanda.risposte[3] ? domanda.risposte[3].testo : null,
      } : null,
    ];
    const risposte_filtrate = risposte.filter(function (el) {
      return el != null;
    });
    e.preventDefault();
    const newQuestion = {
      numero: this.state.numero ? parseFloat(this.state.numero) : parseFloat(domanda.numero),
      regola: this.state.regola ? isFinite(String(this.state.regola))
      ? parseFloat(this.state.regola) : this.state.regola : domanda.regola,
      risposte: risposte_filtrate,
      testo: this.state.testo ? this.state.testo : domanda.testo,
      tipo: this.state.tipo ? this.state.tipo : domanda.tipo,
    };
    firestore
    .set(
        { collection: "domande", doc: this.props.match.params.id },
        newQuestion, {merge: true}
      )
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/quiz/questions"));
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]:
        e.target.name === "inputs"
          ? parseFloat(e.target.value)
          : e.target.value,
    });
  };
  onChangeAnswer = (e) => {
    e.persist();
    const risposta = e.target.id;
    const campo = e.target.name;
    this.setState((prevState) => ({
      [risposta]: {
        ...prevState[risposta],
        [campo]: e.target.value,
      },
    }));
  };

  render() {
    const { domanda } = this.props;
    const { message, messageType } = this.props.notify;
    if (domanda) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/quiz/questions' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                lista domande
              </Link>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <div className='card'>
            <h5 className='card-header'>Aggiungi Domanda</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='numero'>Numero</label>
                      <input
                        type='number'
                        className='form-control'
                        name='numero'
                        id='numero'
                        required
                        onChange={this.onChange}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.numero
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-1'>
                    <div className='form-group'>
                      <label htmlFor='regola'>Regola</label>
                      <input
                        type='text'
                        className='form-control'
                        name='regola'
                        id='regola'
                        required
                        onChange={this.onChange}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.regola
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label htmlFor='tipo'>Tipo</label>
                    <select
                      key={domanda.id === this.props.match.params.id
                        ? domanda.tipo
                        : null}
                      className='form-control'
                      name='tipo'
                      id='tipo'
                      onChange={this.onChange}
                      defaultValue={
                        domanda.id === this.props.match.params.id
                          ? domanda.tipo
                          : null
                      }
                      required
                    >
                      <option value='default'>Seleziona Tipo...</option>
                      <option value='multi'>Risposta multipla</option>
                      <option value='boolean'>Vero/Falso</option>
                    </select>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='testo'
                        rows='4'
                        required
                        onChange={this.onChange}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.testo
                              : null
                          }
                      />
                    </div>
                  </div>
                </div>
                <div className='row' key='r1'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='risposta'>Risposta</label>
                      <input
                        type='text'
                        className='form-control'
                        name='risposta'
                        id='r1'
                        required
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.risposte[0].risposta
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='r1'
                        rows='4'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.risposte[0].testo
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='corretta'>Corretta?</label>
                      <select
                        key={domanda.id === this.props.match.params.id
                          ? domanda.risposte[0].corretta
                          : null}
                        className='form-control'
                        name='corretta'
                        id='r1'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.risposte[0].corretta
                              : null
                          }
                        required
                      >
                        <option value='default'>Seleziona Soluzione...</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row' key='r2'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='risposta'>Risposta</label>
                      <input
                        type='text'
                        className='form-control'
                        name='risposta'
                        id='r2'
                        required
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.risposte[1].risposta
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='r2'
                        rows='4'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.risposte[1].testo
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='corretta'>Corretta?</label>
                      <select
                        key={domanda.id === this.props.match.params.id
                          ? domanda.risposte[1].corretta
                          : null}
                        className='form-control'
                        name='corretta'
                        id='r2'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? domanda.risposte[1].corretta
                              : null
                          }
                        required
                      >
                        <option value='default'>Seleziona Soluzione...</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row' key='r3'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='risposta'>Risposta</label>
                      <input
                        type='text'
                        className='form-control'
                        name='risposta'
                        id='r3'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? (domanda.risposte[2] ? domanda.risposte[2].risposta : null)
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='r3'
                        rows='4'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? (domanda.risposte[2] ? domanda.risposte[2].testo : null)
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='corretta'>Corretta?</label>
                      <select
                        key={domanda.id === this.props.match.params.id ? (domanda.risposte[2] ? domanda.risposte[2].corretta : null) : null }
                        className='form-control'
                        name='corretta'
                        id='r3'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? (domanda.risposte[2] ? domanda.risposte[2].corretta : null)
                              : null
                          }
                      >
                        <option value='default'>Seleziona Soluzione...</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row' key='r4'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='risposta'>Risposta</label>
                      <input
                        type='text'
                        className='form-control'
                        name='risposta'
                        id='r4'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? (domanda.risposte[3] ? domanda.risposte[3].risposta : null)
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='r4'
                        rows='4'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? (domanda.risposte[3] ? domanda.risposte[3].testo : null)
                              : null
                          }
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='corretta'>Corretta?</label>
                      <select
                        key={domanda.id === this.props.match.params.id ? (domanda.risposte[3] ? domanda.risposte[3].corretta : null) : null }
                        className='form-control'
                        name='corretta'
                        id='r4'
                        onChange={this.onChangeAnswer}
                        defaultValue={
                            domanda.id === this.props.match.params.id
                              ? (domanda.risposte[3] ? domanda.risposte[3].corretta : null)
                              : null
                          }
                      >
                        <option value='default'>Seleziona Soluzione...</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>

                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "domande",
      doc: props.match.params.id
    },
  ]),
  connect(
    (state, props) => ({
      domanda: state.firestore.ordered.domande && state.firestore.ordered.domande[0],
      notify: state.notify,
    }),
    { notifyUser }
  )
)(EditQuestion);
