import React, { Component } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";
import { connect } from "react-redux";

class AddVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria: "",
      codice: "",
      disciplinare: "",
      tecnico: "",
      scala: "",
      serie: "",
      spiegazione: "",
      tipo: "",
      fonte: "",
    };
  }
  onSubmit = (e) => {
    e.preventDefault();
    const newVideo = {
      categoria: this.state.categoria,
      codice: this.state.codice,
      disciplinare: this.state.disciplinare,
      tecnico: this.state.tecnico,
      spiegazione: this.state.spiegazione,
      tipo: this.state.tipo,
      serie: this.state.serie,
      scala: this.state.scala,
      fonte: this.state.fonte,
    };
    const { firestore, history, notifyUser } = this.props;
    firestore
      .add({ collection: "video" }, newVideo)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  uploadFile = () => {};
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { message, messageType } = this.props.notify;
    return (
      <div>
        <div className='row'>
          <div className='col-md-6'>
            <Link to='/' className='btn btn-link'>
              <i className='fas fa-arrow-circle-left' /> Indietro alla dashboard
            </Link>
          </div>
        </div>
        {message ? <Alert message={message} messageType={messageType} /> : null}
        <div className='card'>
          <h5 className='card-header'>Aggiungi Video</h5>
          <div className='card-body'>
            <form onSubmit={this.onSubmit}>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='tecnico'>P. tecnico</label>
                    <select
                      className='form-control'
                      name='tecnico'
                      id='tecnico'
                      onChange={this.onChange}
                      value={this.state.tecnico || ""}
                      required
                    >
                      <option value='default'>
                        Seleziona provvedimento tecnico...
                      </option>
                      <option value='Niente'>Niente</option>
                      <option value='Calcio di punizione diretto'>
                        Calcio di punizione diretto
                      </option>
                      <option value='Calcio di punizione indiretto'>
                        Calcio di punizione indiretto
                      </option>
                      <option value='Calcio di rigore'>Calcio di rigore</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='disciplinare'>P. disciplinare</label>
                    <select
                      className='form-control'
                      name='disciplinare'
                      id='disciplinare'
                      onChange={this.onChange}
                      value={this.state.disciplinare || ""}
                      required
                    >
                      <option value='default'>
                        Seleziona provvedimento disciplinare...
                      </option>
                      <option value='Niente'>Niente</option>
                      <option value='Richiamo'>Richiamo</option>
                      <option value='Ammonizione'>Ammonizione</option>
                      <option value='Espulsione'>Espulsione</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className='form-group'>
                    <label htmlFor='spiegazione'>Spiegazione</label>
                    <textarea
                      className='form-control'
                      name='spiegazione'
                      id='spiegazione'
                      rows='4'
                      onChange={this.onChange}
                      value={this.state.spiegazione || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='scala'>Scala</label>
                    <input
                      type='number'
                      className='form-control'
                      name='scala'
                      id='scala'
                      onChange={this.onChange}
                      value={this.state.scala || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='codice'>Codice</label>
                    <input
                      type='text'
                      className='form-control'
                      name='codice'
                      id='codice'
                      required
                      onChange={this.onChange}
                      value={this.state.codice || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='categoria'>Categoria</label>
                    <input
                      type='text'
                      className='form-control'
                      name='categoria'
                      id='categoria'
                      required
                      onChange={this.onChange}
                      value={this.state.categoria || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='tipo'>Tipo</label>
                    <input
                      type='text'
                      className='form-control'
                      name='tipo'
                      id='tipo'
                      required
                      onChange={this.onChange}
                      value={this.state.tipo || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='serie'>Serie</label>
                    <input
                      type='text'
                      className='form-control'
                      name='serie'
                      id='serie'
                      required
                      onChange={this.onChange}
                      value={this.state.serie || ""}
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='fonte'>Fonte</label>
                    <input
                      type='text'
                      className='form-control'
                      name='fonte'
                      id='fonte'
                      required
                      onChange={this.onChange}
                      value={this.state.fonte || ""}
                    />
                  </div>
                </div>
              </div>
              <input
                type='submit'
                className='btn btn-primary btn-block'
                value='Invia'
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect(
    (state, props) => ({
      notify: state.notify,
    }),
    { notifyUser }
  )
)(AddVideo);
