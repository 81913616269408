import React, { Component } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

class AddQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numero: "",
      regola: "",
      testo: "",
      tipo: "",
      inputs: 2,
      r1: {
        risposta: "",
        testo: "",
        corretta: "",
      },
      r2: {
        risposta: "",
        testo: "",
        corretta: "",
      },
      r3: {
        risposta: "",
        testo: "",
        corretta: "",
      },
      r4: {
        risposta: "",
        testo: "",
        corretta: "",
      },
    };
  }
  onSubmit = (e) => {
    const risposte = [
      {
        corretta:
          this.state.r1.corretta.toLowerCase() === "true" ? true : false,
        risposta: this.state.r1.risposta,
        testo: this.state.r1.testo,
      },
      {
        corretta:
          this.state.r2.corretta.toLowerCase() === "true" ? true : false,
        risposta: this.state.r2.risposta,
        testo: this.state.r2.testo,
      },
      this.state.r3.testo !== ""
        ? {
            corretta:
              this.state.r3.corretta.toLowerCase() === "true" ? true : false,
            risposta: this.state.r3.risposta,
            testo: this.state.r3.testo,
          }
        : null,
      this.state.r4.testo !== ""
        ? {
            corretta:
              this.state.r4.corretta.toLowerCase() === "true" ? true : false,
            risposta: this.state.r4.risposta,
            testo: this.state.r4.testo,
          }
        : null,
    ];
    const risposte_filtrate = risposte.filter(function (el) {
      return el != null;
    });
    e.preventDefault();
    const newQuestion = {
      numero: parseFloat(this.state.numero),
      regola: isFinite(String(this.state.regola)) ? parseFloat(this.state.regola) : this.state.regola,
      risposte: risposte_filtrate,
      testo: this.state.testo,
      tipo: this.state.tipo,
    };
    const { firestore, history, notifyUser } = this.props;
    firestore
      .add({ collection: "domande" }, newQuestion)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]:
        e.target.name === "inputs"
          ? parseFloat(e.target.value)
          : e.target.value,
    });
  };
  onChangeAnswer = (e) => {
    e.persist();
    const risposta = e.target.id;
    const campo = e.target.name;
    this.setState((prevState) => ({
      [risposta]: {
        ...prevState[risposta],
        [campo]: e.target.value,
      },
    }));
  };
  render() {
    const { domande } = this.props;
    const { message, messageType } = this.props.notify;
    if (domande) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/quiz' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                lista quiz
              </Link>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <div className='card'>
            <h5 className='card-header'>Aggiungi Domanda</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='numero'>Numero</label>
                      <input
                        type='number'
                        className='form-control'
                        name='numero'
                        id='numero'
                        required
                        onChange={this.onChange}
                        value={this.state.numero || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-1'>
                    <div className='form-group'>
                      <label htmlFor='regola'>Regola</label>
                      <input
                        type='text'
                        className='form-control'
                        name='regola'
                        id='regola'
                        required
                        onChange={this.onChange}
                        value={this.state.regola || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <label htmlFor='tipo'>Tipo</label>
                    <select
                      className='form-control'
                      name='tipo'
                      id='tipo'
                      onChange={this.onChange}
                      value={this.state.tipo}
                      required
                    >
                      <option value='default'>Seleziona Tipo...</option>

                      <option value='multi'>Risposta multipla</option>
                      <option value='boolean'>Vero/Falso</option>
                    </select>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='testo'
                        rows='4'
                        required
                        onChange={this.onChange}
                        value={this.state.testo || ""}
                      />
                    </div>
                  </div>
                </div>
                <div className='row' key='r1'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='risposta'>Risposta</label>
                      <input
                        type='text'
                        className='form-control'
                        name='risposta'
                        id='r1'
                        required
                        onChange={this.onChangeAnswer}
                        value={this.state.r1.risposta || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='r1'
                        rows='4'
                        onChange={this.onChangeAnswer}
                        value={this.state.r1.testo || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='corretta'>Corretta?</label>
                      <select
                        className='form-control'
                        name='corretta'
                        id='r1'
                        onChange={this.onChangeAnswer}
                        value={this.state.r1.corretta}
                        required
                      >
                        <option value='default'>Seleziona Soluzione...</option>

                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row' key='r2'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='risposta'>Risposta</label>
                      <input
                        type='text'
                        className='form-control'
                        name='risposta'
                        id='r2'
                        required
                        onChange={this.onChangeAnswer}
                        value={this.state.r2.risposta || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='r2'
                        rows='4'
                        onChange={this.onChangeAnswer}
                        value={this.state.r2.testo || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='corretta'>Corretta?</label>
                      <select
                        className='form-control'
                        name='corretta'
                        id='r2'
                        onChange={this.onChangeAnswer}
                        value={this.state.r2.corretta}
                        required
                      >
                        <option value='default'>Seleziona Soluzione...</option>

                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row' key='r3'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='risposta'>Risposta</label>
                      <input
                        type='text'
                        className='form-control'
                        name='risposta'
                        id='r3'
                        onChange={this.onChangeAnswer}
                        value={this.state.r3.risposta || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='r3'
                        rows='4'
                        onChange={this.onChangeAnswer}
                        value={this.state.r3.testo || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='corretta'>Corretta?</label>
                      <select
                        className='form-control'
                        name='corretta'
                        id='r3'
                        onChange={this.onChangeAnswer}
                        value={this.state.r3.corretta}
                      >
                        <option value='default'>Seleziona Soluzione...</option>

                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row' key='r4'>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label htmlFor='risposta'>Risposta</label>
                      <input
                        type='text'
                        className='form-control'
                        name='risposta'
                        id='r4'
                        onChange={this.onChangeAnswer}
                        value={this.state.r4.risposta || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-7'>
                    <div className='form-group'>
                      <label htmlFor='testo'>Testo</label>
                      <textarea
                        className='form-control'
                        name='testo'
                        id='r4'
                        rows='4'
                        onChange={this.onChangeAnswer}
                        value={this.state.r4.testo || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <label htmlFor='corretta'>Corretta?</label>
                      <select
                        className='form-control'
                        name='corretta'
                        id='r4'
                        onChange={this.onChangeAnswer}
                        value={this.state.r4.corretta}
                      >
                        <option value='default'>Seleziona Soluzione...</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>

                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "domande",
    },
  ]),
  connect(
    (state, props) => ({
      domande: state.firestore.ordered.domande,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(AddQuestion);
