import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";

class Video extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      fullURL: "",
    };
  }

  componentDidUpdate() {
    const { video, firebase } = this.props;
    if (video) {
      var storageRef = firebase.storage().ref();
      storageRef
        .child(`video/${video.fonte}/${video.codice}.mp4`)
        .getDownloadURL()
        .then((url) => {
          this.setState({ fullURL: url });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    return null;
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    const { video } = this.props;
    if (video && this.state.fullURL) {
      return (
        <div>
          <div className='row mb-3'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-video'></i> Video {video.codice}
              </h2>
            </div>
            <div className='col-md-6'>
              <div className='float-right'>
                <Link
                  to={"/video/" + video.fonte + "/" + video.serie}
                  className='btn btn-link'
                >
                  <i className='fas fa-arrow-circle-left' /> Indietro alla lista
                </Link>
              </div>
            </div>
          </div>
          <ReactPlayer
            url={this.state.fullURL}
            width='100%'
            height='auto'
            controls={true}
            playing={false}
            muted
          />
          <Button variant='primary' block onClick={this.handleShow}>
            Vedi soluzione
          </Button>

          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Soluzione {video.codice}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <b>Provvedimento tecnico:</b> {video.tecnico}
              <br />
              <b>Provvedimento disciplinare:</b> {video.disciplinare}
              <br />
              {video.spiegazione ? (
                <p>
                  <b>Spiegazione:</b> {video.spiegazione}
                </p>
              ) : null}
              <ProgressBar style={{ height: "25px", fontSize: "18px" }}>
                <ProgressBar
                  striped
                  variant={
                    video.disciplinare === "Ammonizione"
                      ? "warning"
                      : video.disciplinare === "Espulsione"
                      ? "danger"
                      : "success"
                  }
                  now={video.scala * 10}
                  key={1}
                  label={video.scala}
                />
              </ProgressBar>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleClose}>
                Chiudi
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "video",
      doc: props.match.params.id,
    },
  ]),
  firebaseConnect(),
  connect((state, props) => ({
    video: state.firestore.ordered.video && state.firestore.ordered.video[0],
  }))
)(Video);
