import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import classnames from "classnames";
import PropTypes from "prop-types";

class AppNavbar extends Component {
  state = {
    isAuthenticated: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;
    if (auth.uid) {
      return { isAuthenticated: true };
    } else {
      return { isAuthenticated: false };
    }
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    const { firebase } = this.props;
    firebase.logout();
  };

  render() {
    const { isAuthenticated } = this.state;
    const { profile } = this.props;
    return (
      <div>
        <nav className='navbar navbar-expand-md navbar-light bg-light mb-4'>
          <div className='container'>
            <Link to='/' className='navbar-brand'>
              <img
                src='https://i1.wp.com/www.aiapinerolo.it/wp-content/uploads/2019/03/BannerIstituzionale.png?w=530&ssl=1'
                alt='Logo'
                width='180'
                height='30%'
                loading='lazy'
                className='d-inline-block align-top'
              />
            </Link>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarMain'
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarMain'>
              {isAuthenticated ? (
                <ul className='navbar-nav ml-auto'>
                  <li className='nav-item active'>
                    <a href='#!' className='nav-link'>
                      <b
                        className={classnames({
                          "text-danger": profile.role === "admin",
                        })}
                      >
                        {profile.firstName} {profile.lastName}
                      </b>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      href='#!'
                      className='nav-link'
                      onClick={this.onLogoutClick}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
AppNavbar.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }))
)(AppNavbar);
