import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import XLSX from "xlsx";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/it";

import Spinner from "../layout/Spinner";
moment.locale("it");

class RtoList extends Component {
  exportRto = () => {
    const { rto } = this.props;
    let rtos = [["#", "Data", "Presenti"]];
    rto.map((r) => {
      let presenti = [];
      r.presenti.map((presente) => {
        presenti.push(presente);
        let rtoArray = [r.numero, r.data, presente];
        rtos.push(rtoArray);
        return null;
      });
      return null;
    });
    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(rtos);
    XLSX.utils.book_append_sheet(wb, wsAll, "Tutte le RTO");
    XLSX.writeFile(wb, "rto-export.xlsx");
  };
  render() {
    const { rto, profile } = this.props;
    if (rto) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-chalkboard-teacher'></i> Lista RTO
              </h2>
            </div>
            <div className='col-md-6'>
              <div className='float-right'>
                <Link to='/' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alle
                  categorie
                </Link>
                {profile.role === "admin" ? (
                  <button className='btn btn-success' onClick={this.exportRto}>
                    Esporta
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className='table-responsive mt-3'>
            <table className='table table-striped table-bordered table-sm text-center'>
              <thead className='thead-inverse'>
                <tr>
                  <th>Numero</th>
                  <th>Data</th>
                  <th>Presenti</th>
                  {profile.role === "admin" ? <th>Modifica</th> : null}
                </tr>
              </thead>
              <tbody>
                {rto.map((riunione) => (
                  <tr key={riunione.id}>
                    <td>{riunione.numero}</td>
                    <td>
                      {moment(riunione.data.toDate()).format("DD-MM-yyyy")}
                    </td>
                    <td>{riunione.presenti.length}</td>
                    {profile.role === "admin" ? (
                      <td>
                        <Link
                          to={`/rto/edit/${riunione.id}`}
                          className='btn btn-warning btn-sm'
                        >
                          <i className='fas fa-pen' />
                        </Link>
                      </td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}

RtoList.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "rto",
      orderBy: "numero",
    },
  ]),
  connect((state, props) => ({
    rto: state.firestore.ordered.rto,
    profile: state.firebase.profile,
  }))
)(RtoList);
