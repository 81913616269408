import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {Modal, Button} from 'react-bootstrap'
import PropTypes from "prop-types";
import classnames from "classnames";
import Spinner from "../layout/Spinner";

class QuizList extends Component {
  state = {
    show: false,
    dQuiz: []
  }

  onAvailableClick = (id, disponibile) => {
    const {firestore} = this.props;
    firestore.collection('quiz').doc(id).set({
      disponibile: disponibile ? false : true
  }, { merge: true });
  }
  onGetQuestions = (quiz) => {
    const {firestore} = this.props;
    const {domande} = quiz;
    const dQuiz = []
    domande.map((domanda) => {
      firestore.collection('domande').where('numero', '==', domanda).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const data = {id: doc.id, numero: doc.data().numero, regola: doc.data().regola, risposte: doc.data().risposte, testo: doc.data().testo, tipo: doc.data().boolean}
            dQuiz.push(data)
        }
      )})
      return null
    });
    Promise.all(domande).then(() => {
      this.setState({
        dQuiz,
      });
    });
  }
  handleShow = () => {
    this.setState({show: true})
  }
  handleClose = () => {
    this.setState({ show: false });
  }
  
  render() {
    const { quiz, profile } = this.props;
    if (quiz) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <h2>
                {" "}
                <i className='fas fa-running'></i> Lista Quiz
              </h2>
            </div>
            <div className='col-md-6'>
              <div className='float-right'>
                <Link to='/' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alla
                  dashboard
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div className='table-responsive'>
            <table className='table table-striped table-bordered table-sm text-center'>
              <thead className='thead-inverse'>
                <tr>
                  <th>Codice</th>
                  <th>Tipo</th>
                  <th>Disponibile</th>
                  <th>Vai al quiz</th>
                  {profile.role === "admin" ? <th>Modifica</th> : null}
                </tr>
              </thead>
              <tbody>
                {quiz.map((quiz) => (
                  <tr key={quiz.id}>
                    <td>{quiz.codice}</td>
                    <td>{quiz.tipo}</td>
                    <td>{quiz.disponibile === true ? "Si" : "No"}</td>
                    <td>
                      <Link
                        to={`/quiz/${quiz.id}`}
                        className={classnames("btn btn-sm btn-primary", {
                          disabled: quiz.disponibile !== true,
                        })}
                      >
                        <i className='fa fa-arrow-circle-right'></i>
                      </Link>
                    </td>
                    {profile.role === "admin" ? (
                      <td>
                        <button
                          onClick={() => this.onAvailableClick(quiz.id, quiz.disponibile)}
                          className='btn btn-sm mr-1'
                        >
                          <i className={quiz.disponibile ? 'fas fa-lock-open' : 'fas fa-lock'} />
                        </button>
                        <button
                          onClick={() => this.onGetQuestions(quiz)}
                          className='btn btn-sm mr-1'
                        >
                          <i className='fas fa-download' />
                        </button>
                        <button
                          onClick={this.handleShow}
                          className="btn btn-sm mr-1 btn-primary"
                        >
                          <i className='fas fa-question' />
                        </button>
                        <Link
                          to={`/quiz/answers/${quiz.codice}`}
                          className='btn btn-success btn-sm mr-1'
                        >
                          <i className='fas fa-check' />
                        </Link>
                        <Link
                          to={`/quiz/edit/${quiz.id}`}
                          className='btn btn-warning btn-sm'
                        >
                          <i className='fas fa-pen' />
                        </Link>
                        <Modal show={this.state.show} onHide={this.handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Domande del quiz</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                          {this.state.dQuiz.map((d) => (
                            <div key={d.id}>
                              <h5 className='text-center'>Domanda {d.numero} | Regola {d.regola}</h5>
                              {d.testo}
                              <br/>
                              <h5 className='text-center'>Risposte</h5> {
                                d.risposte.map((r) => (
                                  <div key={d.id+r.risposta}>
                                    <b>Risposta: </b>{r.risposta}<br/>
                                    {r.testo ? <span><b>Testo:</b> {r.testo}<br /></span> : null}
                                    <b>Corretta?</b> {r.corretta === true ? (<span className='badge badge-sm badge-success'>Si</span>) : (<span className='badge badge-sm badge-danger'>No</span>)}
                                  </div>
                                ))
                              }
                              <hr/>
                            </div>
                          ))}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant='secondary' onClick={this.handleClose}>
                              Chiudi
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </td>
                      
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return <Spinner></Spinner>;
    }
  }
}
QuizList.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "quiz",
      orderBy: "codice",
    },
  ]),
  connect((state, props) => ({
    quiz: state.firestore.ordered.quiz,
    risposta: state.firestore.ordered.risposte && state.firestore.ordered.risposte[0],
    profile: state.firebase.profile,
  }))
)(QuizList);
