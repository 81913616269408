import React from "react";
import "./footer.css";
export default () => {
  return (
    <div className='pt-5'>
      <footer className='footer'>
        <div className='container text-center'>
          <span className='text-muted '>
            AIA Sezione di Pinerolo - Tutti i diritti sono riservati
          </span>
        </div>
      </footer>
    </div>
  );
};
