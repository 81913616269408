import React, { Component } from "react";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

class AddQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      codice: "",
      domande: [],
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }
  onSubmit = (e) => {
    e.preventDefault();
    const newQuiz = {
      codice: this.state.codice,
      domande: this.state.domande,
      tipo: this.state.tipo,
      disponibile: this.state.disponibile.toLowerCase() === "true" ? true : false
    };
    const { firestore, history, notifyUser } = this.props;
    firestore
      .add({ collection: "quiz" }, newQuiz)
      .catch((e) => {
        notifyUser(e.message, "error");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .then(() => history.push("/"));
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleCheckbox(e) {
    const item = e.target.name;
    if (e.target.checked) {
      this.setState((prevState) => prevState.domande.push(parseFloat(item)));
    } else {
      this.setState((prevState) => prevState.domande.pop(parseFloat(item)));
    }
  }
  render() {
    const { domande } = this.props;
    const { message, messageType } = this.props.notify;
    if (domande) {
      return (
        <div>
          <div className='row'>
            <div className='col-md-6'>
              <Link to='/quiz' className='btn btn-link'>
                <i className='fas fa-arrow-circle-left' /> Indietro alla
                lista quiz
              </Link>
            </div>
          </div>
          {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null}
          <div className='card'>
            <h5 className='card-header'>Aggiungi Quiz</h5>
            <div className='card-body'>
              <form onSubmit={this.onSubmit}>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='codice'>Codice</label>
                      <input
                        type='text'
                        className='form-control'
                        name='codice'
                        id='codice'
                        required
                        onChange={this.onChange}
                        value={this.state.codice || ""}
                      />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='tipo'>Tipo</label>
                      <select
                      className='form-control'
                      name='tipo'
                      id='tipo'
                      onChange={this.onChange}
                      value={this.state.tipo}
                      required
                    >
                      <option value='default'>Seleziona Tipo...</option>
                      <option value='Esercitazione'>Esercitazione</option>
                      <option value='Esame'>Esame</option>
                    </select>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='disponibile'>Disponibile</label>
                      <select
                      className='form-control'
                      name='disponibile'
                      id='disponibile'
                      onChange={this.onChange}
                      value={this.state.disponibile}
                      required
                    >
                      <option value='default'>Seleziona Tipo...</option>
                      <option value={true}>Si</option>
                       <option value={false}>No</option>
                    </select>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    <table className='table table-stripped '>
                      <thead className='thead-inverse'>
                        <tr>
                          <th>Numero</th>
                          <th>Regola</th>
                          <th>Tipo</th>
                          <th className='text-center'>Domande selezionate: {this.state.domande.length}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {domande.map((domanda) => (
                          <tr key={domanda.id}>
                            <td>{domanda.numero}</td>
                            <td>{domanda.regola}</td>
                            <td>{domanda.tipo}</td>
                            <td className='text-center'>
                              <input
                                type='checkbox'
                                name={domanda.numero}
                                id={domanda.id}
                                onChange={this.handleCheckbox}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Invia'
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

export default compose(
  firestoreConnect((props) => [
    {
      collection: "domande",
      orderBy: 'regola'
    },
  ]),
  connect(
    (state, props) => ({
      domande: state.firestore.ordered.domande,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(AddQuiz);
