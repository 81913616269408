import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import { Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import "moment/locale/it";
import { notifyUser } from "../../actions/notify";
import Alert from "../layout/Alert";

import Spinner from "../layout/Spinner";
moment.locale("it");

class EditMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria: "",
      data: "",
      home: "",
      away: "",
      oa: "",
      ot: "",
      voto: "",
      homeResult: "",
      awayResult: "",
      rimborso: "",
      status: "",
      statusRimborso: "",
      utente: "",
      falloHomeA: "",
      falloAwayA: "",
      protesteHomeA: "",
      protesteAwayA: "",
      antisportivoHomeA: "",
      antisportivoAwayA: "",
      altroHomeA: "",
      altroAwayA: "",
      falloHomeE: "",
      falloAwayE: "",
      condottaAwayE: "",
      condottaHomeE: "",
      protesteHomeE: "",
      protesteAwayE: "",
      dogsoHomeE: "",
      dogsoAwayE: "",
      dpaHomeE: "",
      dpaAwayE: "",
      altroHomeE: "",
      altroAwayE: "",
    };
  }
  onSubmit = (e) => {
    e.preventDefault();
    const { firestore, partita, notifyUser } = this.props;
    const updatepartita = {
      categoria: this.state.categoria
        ? this.state.categoria
        : partita.categoria,
      partita: {
        home: this.state.home ? this.state.home : partita.partita.home,
        away: this.state.away ? this.state.away : partita.partita.away,
      },
      risultato: {
        home: this.state.homeResult
          ? parseFloat(this.state.homeResult)
          : partita.risultato.home,
        away: this.state.awayResult
          ? parseFloat(this.state.awayResult)
          : partita.risultato.away,
      },
      utente: this.state.utente ? this.state.utente : partita.utente,
      data: this.state.data ? new Date(this.state.data) : partita.data,
      ot: this.state.ot
        ? this.state.ot === "true"
          ? true
          : false
        : partita.ot,
      oa: this.state.oa
        ? this.state.oa === "true"
          ? true
          : false
        : partita.oa,
      voto: this.state.voto ? parseFloat(this.state.voto) : partita.voto,
      status: this.state.status ? this.state.status : partita.status,
      statusRimborso: this.state.statusRimborso
        ? this.state.statusRimborso
        : partita.statusRimborso,
      rimborso: this.state.rimborso
        ? parseFloat(this.state.rimborso)
        : partita.rimborso,
      ammonizioni: {
        home: {
          fallo: this.state.falloHomeA
            ? parseFloat(this.state.falloHomeA)
            : partita.ammonizioni.home.fallo,
          proteste: this.state.protesteHomeA
            ? parseFloat(this.state.protesteHomeA)
            : partita.ammonizioni.home.proteste,
          antisportivo: this.state.antisportivoHomeA
            ? parseFloat(this.state.antisportivoHomeA)
            : partita.ammonizioni.home.antisportivo,
          altro: this.state.altroHomeA
            ? parseFloat(this.state.altroHomeA)
            : partita.ammonizioni.home.altro,
        },
        away: {
          fallo: this.state.falloAwayA
            ? parseFloat(this.state.falloAwayA)
            : partita.ammonizioni.away.fallo,
          proteste: this.state.protesteAwayA
            ? parseFloat(this.state.protesteAwayA)
            : partita.ammonizioni.away.proteste,
          antisportivo: this.state.antisportivoAwayA
            ? parseFloat(this.state.antisportivoAwayA)
            : partita.ammonizioni.away.antisportivo,
          altro: this.state.altroAwayA
            ? parseFloat(this.state.altroAwayA)
            : partita.ammonizioni.away.altro,
        },
      },
      espulsioni: {
        home: {
          fallo: this.state.falloHomeE
            ? parseFloat(this.state.falloHomeE)
            : partita.espulsioni.home.fallo,
          condotta: this.state.condottaHomeE
            ? parseFloat(this.state.condottaHomeE)
            : partita.espulsioni.home.condotta,
          altro: this.state.altroHomeE
            ? parseFloat(this.state.altroHomeE)
            : partita.espulsioni.home.altro,
          proteste: this.state.protesteHomeE
            ? parseFloat(this.state.protesteHomeE)
            : partita.espulsioni.home.proteste,
          dogso: this.state.dogsoHomeE
            ? parseFloat(this.state.dogsoHomeE)
            : partita.espulsioni.home.dogso,
          dpa: this.state.dpaHomeE
            ? parseFloat(this.state.dpaHomeE)
            : partita.espulsioni.home.dpa,
        },
        away: {
          fallo: this.state.falloAwayE
            ? parseFloat(this.state.falloAwayE)
            : partita.espulsioni.away.fallo,
          condotta: this.state.condottaAwayE
            ? parseFloat(this.state.condottaAwayE)
            : partita.espulsioni.away.condotta,
          altro: this.state.altroAwayE
            ? parseFloat(this.state.altroAwayE)
            : partita.espulsioni.away.altro,
          proteste: this.state.protesteAwayE
            ? parseFloat(this.state.protesteAwayE)
            : partita.espulsioni.away.proteste,
          dogso: this.state.dogsoAwayE
            ? parseFloat(this.state.dogsoAwayE)
            : partita.espulsioni.away.dogso,
          dpa: this.state.dpaAwayE
            ? parseFloat(this.state.dpaAwayE)
            : partita.espulsioni.away.dpa,
        },
      },
    };
    firestore
      .update({ collection: "partite", doc: partita.id }, updatepartita)
      .then(() => {
        notifyUser("Modifiche alla partita salvate correttamente.", "success");
        setTimeout(() => notifyUser(null, null), 4000);
      })
      .catch((e) => {
        notifyUser("Errore. Riprovare o contattare il webmaster.", "error");
        setTimeout(() => notifyUser(null, null), 4000);
      });
  };
  onDeleteClick = () => {
    const { partita, firestore, history } = this.props;
    firestore
      .delete({ collection: "partite", doc: partita.id })
      .then(history.push("/"));
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  static getDerivedStateFromProps(props, state) {
    const { partita } = props;
    if (partita && state.utente === "" && state.voto === "") {
      return {
        utente: partita.utente,
        voto: parseFloat(partita.voto).toFixed(2),
      };
    }
    return null;
  }
  render() {
    const { users, partita, profile, auth } = this.props;
    const { message, messageType } = this.props.notify;

    if (users && partita) {
      if (profile.role === "admin" || auth.uid === this.props.match.params.id) {
        return (
          <div>
            {message ? (
              <Alert message={message} messageType={messageType} />
            ) : null}
            <div className='row'>
              <div className='col-md-6'>
                <Link to='/' className='btn btn-link'>
                  <i className='fas fa-arrow-circle-left' /> Indietro alla
                  dashboard
                </Link>
              </div>
              <div className='col-md-6'>
                <div className='btn-group float-right'>
                  <button
                    onClick={this.onDeleteClick}
                    className='btn btn-danger'
                  >
                    Elimina
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div className='card'>
              <h5 className='card-header'>Modifica Partita</h5>
              <div className='card-body'>
                <form onSubmit={this.onSubmit}>
                  <Tabs defaultActiveKey='pre-gara' id='formPartita'>
                    <Tab eventKey='pre-gara' title='Dati partita'>
                      <div className='row'>
                        <div className='col-md-8'>
                          <div className='form-group'>
                            <label htmlFor='utente'>Associato</label>
                            <select
                              className='form-control'
                              name='utente'
                              id='utente'
                              onChange={this.onChange}
                              value={this.state.utente}
                            >
                              <option value='default'>
                                Seleziona Associato...
                              </option>
                              {profile.role === "admin" ? (
                                users.map((user) => (
                                  <option key={user.id} value={user.id}>
                                    [{user.meccanografico}] {user.firstName}{" "}
                                    {user.lastName}
                                  </option>
                                ))
                              ) : (
                                <option value={auth.uid}>
                                  [{profile.meccanografico}] {profile.firstName}{" "}
                                  {profile.lastName}
                                </option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='form-group'>
                            <label htmlFor='data'>Data</label>
                            <input
                              type='datetime-local'
                              className='form-control'
                              name='data'
                              id='data'
                              required
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? moment(partita.data.toDate()).format(
                                      "yyyy-MM-DDThh:mm"
                                    )
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-4'>
                          <div className='form-group'>
                            <label htmlFor='categoria'>Categoria</label>
                            <select
                              className='form-control'
                              name='categoria'
                              id='categoria'
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? partita.categoria
                                  : null
                              }
                            >
                              <option value='default'>
                                Seleziona Categoria...
                              </option>
                              <option value='GIR'>
                                GIR - Giovanissimi Regionali
                              </option>
                              <option value='GIP'>
                                GIP - Giovanissimi Provinciali
                              </option>
                              <option value='GBP'>
                                GBP - Giovanissimi Fascia B Provinciali
                              </option>
                              <option value='GRB'>
                                GRB - Giovanissimi Fascia B Regionali
                              </option>
                              <option value='ALR'>
                                ALR - Allievi Regionali
                              </option>
                              <option value='ALP'>
                                ALP - Allievi Provinciali
                              </option>
                              <option value='ALB'>
                                ALB - Allievi Fascia B Provinciali
                              </option>
                              <option value='ARB'>
                                ARB - Allievi Fascia B Regionali
                              </option>
                              <option value='JUP'>
                                JUP - Juniores Provinciali
                              </option>
                              <option value='JUR'>
                                JUR - Juniores Regionali
                              </option>
                              <option value='SEC'>
                                SEC - Seconda Categoria
                              </option>
                              <option value='TER'>TER - Terza Categoria</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='form-group'>
                            <label htmlFor='home'>Squadra Ospitante</label>
                            <input
                              type='text'
                              className='form-control'
                              name='home'
                              id='home'
                              required
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? partita.partita.home
                                  : null
                              }
                              placeholder='Inserisci squadra in casa'
                            />
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className='form-group'>
                            <label htmlFor='away'>Squadra Ospitata</label>
                            <input
                              type='text'
                              className='form-control'
                              name='away'
                              id='away'
                              required
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? partita.partita.away
                                  : null
                              }
                              placeholder='Inserisci squadra in trasferta'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-3'>
                          <label htmlFor='risultato'>Risultato</label>
                          <div className='row' id='risultato'>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='homeResult'
                                  id='homeResult'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.risultato.home.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='awayResult'
                                  id='awayResult'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.risultato.away.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className='form-group'>
                            <label htmlFor='oa'>OA</label>
                            <select
                              className='form-control'
                              name='oa'
                              id='oa'
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? partita.oa
                                  : null
                              }
                            >
                              <option value='default'>
                                Seleziona Opzione...
                              </option>
                              <option value={Boolean(false)}>No</option>
                              <option value={Boolean(true)}>Sì</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className='form-group'>
                            <label htmlFor='ot'>OT</label>
                            <select
                              className='form-control'
                              name='ot'
                              id='ot'
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? partita.ot
                                  : null
                              }
                            >
                              <option value='default'>
                                Seleziona Opzione...
                              </option>
                              <option value={Boolean(false)}>No</option>
                              <option value={Boolean(true)}>Sì</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className='form-group'>
                            <label htmlFor='voto'>Voto</label>
                            <select
                              className='form-control'
                              name='voto'
                              id='voto'
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? parseFloat(partita.voto)
                                      .toFixed(2)
                                      .toString()
                                  : null
                              }
                            >
                              <option value='default'>Seleziona Voto...</option>
                              <option value='NaN'>No Voto</option>
                              <option value='8.20'>8.20</option>
                              <option value='8.30'>8.30</option>
                              <option value='8.40'>8.40</option>
                              <option value='8.50'>8.50</option>
                              <option value='8.60'>8.60</option>
                              <option value='8.70'>8.70</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className='form-group'>
                            <label htmlFor='status'>Status Partita</label>
                            <select
                              className='form-control'
                              name='status'
                              id='status'
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? partita.status
                                  : null
                              }
                            >
                              <option value='default'>
                                Seleziona status...
                              </option>
                              <option value='Giocata'>Giocata</option>
                              <option value='Rinviata'>Rinviata</option>
                              <option value='Sospesa'>Sospesa</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className='form-group'>
                            <label htmlFor='rimborso'>Rimborso</label>
                            <input
                              type='number'
                              className='form-control'
                              name='rimborso'
                              id='rimborso'
                              placeholder='Inserisci rimborso in €'
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? partita.rimborso.toString()
                                  : null
                              }
                            />
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className='form-group'>
                            <label htmlFor='statusRimborso'>
                              Status Rimborso
                            </label>
                            <select
                              className='form-control'
                              name='statusRimborso'
                              id='statusRimborso'
                              onChange={this.onChange}
                              defaultValue={
                                partita.id === this.props.match.params.id
                                  ? partita.statusRimborso
                                  : null
                              }
                            >
                              <option value='default'>
                                Seleziona status...
                              </option>
                              <option value='rimborsata'>Rimborsata</option>
                              <option value='non rimborsata'>
                                Non rimborsata
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey='post-gara' title='Provvedimenti'>
                      <div className='row'>
                        <div className='col-md-3'>
                          <label htmlFor='ammonizioniHome'>
                            <b>Ammonizioni Casa</b>
                          </label>
                          <div className='row' id='ammonizioniHome'>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='falloHomeA'>Fallo</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='falloHomeA'
                                  id='falloHomeA'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.ammonizioni.home.fallo.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='protesteHomeA'>Proteste</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='protesteHomeA'
                                  id='protesteHomeA'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.ammonizioni.home.proteste.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='antisportivoHomeA'>DPA</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='antisportivoHomeA'
                                  id='antisportivoHomeA'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.ammonizioni.home.antisportivo.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='altroHomeA'>Altro</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='altroHomeA'
                                  id='altroHomeA'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.ammonizioni.home.altro.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <label htmlFor='ammonizioniAway'>
                            <b>Ammonizioni Ospiti</b>
                          </label>
                          <div className='row' id='ammonizioniAway'>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='falloAwayA'>Fallo</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='falloAwayA'
                                  id='falloAwayA'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.ammonizioni.away.fallo.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='protesteAwayA'>Proteste</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='protesteAwayA'
                                  id='protesteAwayA'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.ammonizioni.away.proteste.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='antisportivoAwayA'>
                                  C. antisporivo
                                </label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='antisportivoAwayA'
                                  id='antisportivoAwayA'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.ammonizioni.away.antisportivo.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='altroAwayA'>Altro</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='altroAwayA'
                                  id='altroAwayA'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.ammonizioni.away.altro.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <label htmlFor='espulsioniHome'>
                            <b>Espulsioni Casa</b>
                          </label>
                          <div className='row' id='espulsioniHome'>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='falloHomeE'>
                                  Grave fallo di gioco
                                </label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='falloHomeE'
                                  id='falloHomeE'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.home.fallo.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='condottaHomeE'>
                                  Condotta Violenta
                                </label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='condottaHomeE'
                                  id='condottaHomeE'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.home.condotta.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='dogsoHomeE'>D.O.G.S.O.</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='dogsoHomeE'
                                  id='dogsoHomeE'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.home.dogso.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='dpaHomeE'>Doppia ammo.</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='dpaHomeE'
                                  id='dpaHomeE'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.home.dpa.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='protesteHomeE'>Proteste</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='protesteHomeE'
                                  id='protesteHomeE'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.home.proteste.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='altroHomeE'>Altro</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='altroHomeE'
                                  id='altroHomeE'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.home.altro.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <label htmlFor='espulsioniAway'>
                            <b>Espulsioni Ospiti</b>
                          </label>
                          <div className='row' id='espulsioniAway'>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='falloAwayE'>
                                  Grave fallo di gioco
                                </label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='falloAwayE'
                                  id='falloAwayE'
                                  maxLength='2'
                                  placeholder='Casa'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.away.fallo.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='condottaAwayE'>
                                  Condotta Violenta
                                </label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='condottaAwayE'
                                  id='condottaAwayE'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.away.condotta.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='dogsoAwayE'>D.O.G.S.O.</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='dogsoAwayE'
                                  id='dogsoAwayE'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.away.dogso.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='dpaAwayE'>Doppia ammo.</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='dpaAwayE'
                                  id='dpaAwayE'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.away.dpa.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='protesteAwayE'>Proteste</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='protesteAwayE'
                                  id='protesteAwayE'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.away.proteste.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label htmlFor='altroAwayE'>Altro</label>
                                <input
                                  type='number'
                                  className='form-control'
                                  name='altroAwayE'
                                  id='altroAwayE'
                                  maxLength='2'
                                  placeholder='Ospiti'
                                  onChange={this.onChange}
                                  defaultValue={
                                    partita.id === this.props.match.params.id
                                      ? partita.espulsioni.away.altro.toString()
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>

                  <input
                    type='submit'
                    className='btn btn-primary btn-block'
                    value='Invia'
                  />
                </form>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <h1 className='text-center text-danger mt-5'>
            Autorizzazioni insufficienti!
          </h1>
        );
      }
    } else {
      return <Spinner />;
    }
  }
}

EditMatch.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "utenti",
      storeAs: "users",
    },
  ]),
  firestoreConnect((props) => [
    {
      collection: "partite",
      storeAs: "partita",
      doc: props.match.params.id,
    },
  ]),
  connect(
    (state, props) => ({
      users: state.firestore.ordered.users,
      partita:
        state.firestore.ordered.partita && state.firestore.ordered.partita[0],
      profile: state.firebase.profile,
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(EditMatch);
